import React, { useState } from "react";
import {
  useGetStudentByIdQuery,
  useUpdateStudentGenotypeMutation,
  useAddParentMutation,
  useGetParentsAddedBySchoolQuery,
  useGetAllStudentActivitiesQuery,
} from "../../../app/services/schoolApi";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import Modal from "../../Modal";
import EnrolStudent from "./EnrolStudent";
import { Button } from "../../FormElements";
import AddParent from "./AddParent";
import Select from "react-select";
import NewActivity from "./NewActivity";
import Parent from "../SchoolParents/Parent";

interface OptionType {
  value: string;
  label: string;
}

const SingleStudent = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: studentId } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isParentModalOpen, setIsParentModalOpen] = useState<boolean>(false);
  const [selectedParent, setSelectedParent] = useState<OptionType | null>(null);

  const { data, isLoading, isError, refetch } = useGetStudentByIdQuery({
    studentId,
    schoolId,
  });

  const {
    data: sdata,
    isLoading: sIsLoading,
    isError: sIsError,
  } = useGetAllStudentActivitiesQuery({
    schoolId,
    studentId,
  });

  const [
    addParentToStudent,
    { isLoading: isAddingParent, isError: isAddParentError },
  ] = useAddParentMutation();

  const { data: parentData } = useGetParentsAddedBySchoolQuery(schoolId);
  const [genotype, setGenotype] = useState("");
  const [
    updateStudentGenotype,
    { isLoading: isUpdating, isError: isUpdateError },
  ] = useUpdateStudentGenotypeMutation();

  const handleAddGenotype = async () => {
    try {
      await updateStudentGenotype({ schoolId, studentId, genotype }).unwrap();
      setGenotype("");
      refetch();
    } catch (error) {
      console.error("Failed to update genotype", error);
    }
  };

  const handleAddParent = async () => {
    if (selectedParent) {
      console.log(selectedParent.value);
      try {
        await addParentToStudent({
          schoolId,
          studentId,
          parentId: selectedParent.value,
        }).unwrap();

        setSelectedParent(null);
        refetch();
      } catch (error) {
        console.error("Failed to add parent to student", error);
      }
    } else {
      setIsParentModalOpen(true);
    }
  };

  const handleAddStudent = () => {
    setIsOpen(true);
  };

  return (
    <Container>
      <Wrapper>
        <h3>Student Details</h3>
        <small>
          View student's details here. Add activities. Match with parents
        </small>

        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Something went wrong!</p>
        ) : data ? (
          <ContentWrapper>
            <ItemWrapper>
              <strong>Full Name: </strong>
              <p>{data.fullName}</p>
            </ItemWrapper>
            <ItemWrapper>
              <strong>Gender: </strong>
              <p>{data.gender}</p>
            </ItemWrapper>
            <ItemWrapper>
              <strong>Class: </strong>
              <p>{data.studentClass}</p>
            </ItemWrapper>
            <ItemWrapper>
              <strong>Genotype: </strong>
              <p>
                {data.genotype === "" ? (
                  <GenotypeWrapper>
                    <GenotypeInput
                      type="text"
                      value={genotype}
                      onChange={(e) => setGenotype(e.target.value)}
                      placeholder="Enter genotype"
                    />
                    <GenoButton
                      onClick={handleAddGenotype}
                      disabled={isUpdating}
                    >
                      {isUpdating ? <Spinner color={colors.white} /> : "Add"}
                    </GenoButton>
                    {isUpdateError && (
                      <p>Failed to update genotype. Please try again.</p>
                    )}
                  </GenotypeWrapper>
                ) : (
                  data.genotype
                )}
              </p>
            </ItemWrapper>

            <ActivitiesContent>
              {data.activities.length === 0 ? (
                <Content>
                  <TopWrapper>
                    <h3>Activities enrolled for</h3>
                    <ButtonWrapper>
                      <Button
                        color={colors.white}
                        backgroundColor={colors.primary}
                        border="none"
                        onClick={handleAddStudent}
                        text="Enrol student"
                      />
                    </ButtonWrapper>
                  </TopWrapper>
                  <p>Student not enrolled for any activity</p>
                </Content>
              ) : (
                <Content>
                  <TopWrapper>
                    <h3>Activities enrolled for</h3>
                    <ButtonWrapper>
                      <Button
                        color={colors.white}
                        backgroundColor={colors.primary}
                        border="none"
                        onClick={handleAddStudent}
                        text="Enrol new"
                      />
                    </ButtonWrapper>
                  </TopWrapper>
                  <Activities>
                    {sIsLoading ? (
                      <Spinner color={colors.primary} />
                    ) : sIsError ? (
                      <p>Oops! Something went wrong</p>
                    ) : (
                      sdata.map((activity: any) => (
                        <NewActivity
                          key={activity._id}
                          to={activity._id}
                          title={activity.title}
                          mode={activity.mode}
                        />
                      ))
                    )}
                  </Activities>
                </Content>
              )}

              <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <EnrolStudent closeModal={() => setIsOpen(false)} />
              </Modal>
            </ActivitiesContent>

            <ActivitiesContent>
              {data.parent.length === 0 ? (
                <Content>
                  <TopWrapper>
                    <h3>Parents of this student</h3>
                    <ParentButtonWrapper>
                      <Button
                        color={colors.white}
                        backgroundColor={colors.primary}
                        border="none"
                        onClick={handleAddParent}
                        text="Add Parent"
                        disabled={isAddingParent}
                      />
                      <Select
                        options={parentData?.map((parent: any) => ({
                          value: parent._id,
                          label: `${parent.firstName} ${parent.lastName}`,
                        }))}
                        value={selectedParent}
                        onChange={(option) =>
                          setSelectedParent(option as OptionType)
                        }
                        placeholder="Select a parent"
                      />

                      {isAddParentError && (
                        <p>Failed to add parent. Please try again.</p>
                      )}
                    </ParentButtonWrapper>
                  </TopWrapper>
                  <p>No parents added yet</p>
                </Content>
              ) : data.parent.length === 2 ? (
                <Content>
                  <TopWrapper>
                    <h3>Parents of this student</h3>
                  </TopWrapper>
                  {data.parent.map((item: any) => (
                    <Parent
                      key={item._id}
                      linkTo={`/school-dashboard/parents/${item._id}`}
                      title={`${item.firstName} ${item.lastName}`}
                      wards={item.wards.length}
                    />
                  ))}
                </Content>
              ) : data.parent.length === 1 ? (
                <Content>
                  <TopWrapper>
                    <h3>Parents of this student</h3>
                    <ParentButtonWrapper>
                      <Button
                        color={colors.white}
                        backgroundColor={colors.primary}
                        border="none"
                        onClick={handleAddParent}
                        text="Add Parent"
                        disabled={isAddingParent}
                      />
                      <Select
                        options={parentData.map((parent: any) => ({
                          value: parent._id,
                          label: `${parent.firstName} ${parent.lastName}`,
                        }))}
                        value={selectedParent}
                        onChange={(option) =>
                          setSelectedParent(option as OptionType)
                        }
                        placeholder="Select a parent"
                      />

                      {isAddParentError && (
                        <p>Failed to add parent. Please try again.</p>
                      )}
                    </ParentButtonWrapper>
                  </TopWrapper>
                  {data.parent.map((item: any) => (
                    <Parent
                      key={item._id}
                      linkTo={`/school-dashboard/parents/${item._id}`}
                      title={`${item.firstName} ${item.lastName}`}
                      wards={item.wards.length}
                    />
                  ))}
                </Content>
              ) : null}
            </ActivitiesContent>
            <Modal
              open={isParentModalOpen}
              onClose={() => setIsParentModalOpen(false)}
            >
              <AddParent
                closeModal={() => setIsParentModalOpen(false)}
                studentId={studentId}
              />
            </Modal>
          </ContentWrapper>
        ) : (
          <p>No student data found!</p>
        )}
      </Wrapper>
    </Container>
  );
};

export default SingleStudent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 2rem auto;

  h3 {
    font-size: 1.2rem;
  }

  small {
    font-size: 0.7rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1.5fr;
  width: 100%;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 0.5fr 1fr;
  }

  strong {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
    text-transform: capitalize;
  }
`;

const GenotypeWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
`;

const GenotypeInput = styled.input`
  padding: 0.5rem;
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  width: 4rem;
`;

const GenoButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  outline: none;
  border-radius: 0.25rem;
  cursor: pointer;
  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;

const ActivitiesContent = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  background-color: ${colors.blueBack};
  margin: 1rem auto;
  border-radius: 0.5rem;
  padding: 1rem;
  flex-direction: column;

  h3 {
    font-size: 1rem;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  h3 {
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Activities = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ParentButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 0.5rem;
`;
