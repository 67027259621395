import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../../../utils";
import Pagination from "../../../common/Pagination";
import ActivityCard from "../../../ActivityCard";
import Spinner from "../../../../utils/Spinner";
import SearchBox from "../../../SearchBox";
import { useGetAllAdminActivitiesQuery } from "../../../../app/services/adminApi";
import AllAdminApprovedAndUnapprovedActivities from "./AllAdminApprovedAndUnapprovedActivities";
import ApprovedActivities from "./ApprovedActivities";
import UnapprovedActivities from "./UnapprovedActivities";
import TabsWrapper from "../../../common/TabsWrapper";

const tabItems = ["All", "Approved", "Unapproved"];

const AdminActivities = () => {
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <AllAdminApprovedAndUnapprovedActivities />;
      case tabItems[1]:
        return <ApprovedActivities />;
      case tabItems[2]:
        return <UnapprovedActivities />;
      case tabItems[3]:
      default:
        return <AllAdminApprovedAndUnapprovedActivities />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList className="sub-nav">
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  return (
    <Container>
      <Wrapper>
        {/* <SearchWrapper>
          <SearchBox dashboardType="admin" />
        </SearchWrapper> */}
        <TabsWrapper renderNavItems={renderNavItems}>
          <TabsContent>{renderChildren()}</TabsContent>
        </TabsWrapper>
      </Wrapper>
    </Container>
  );
};

export default AdminActivities;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  margin: 1rem auto 2rem;
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.primary};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  padding-bottom: 2rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 40%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;
