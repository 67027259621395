import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

//Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const partnerApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}partners/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.partner.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Partner"],
  endpoints: (builder) => ({
    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Login a Partner
    loginPartner: builder.mutation({
      query: (data) => {
        return {
          url: "partner-signin",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new Partner
    signupPartner: builder.mutation({
      query: (data) => {
        return {
          url: "partner-signup",
          method: "POST",
          body: data,
        };
      },
    }),

    //Update Avatar
    updateAvatar: builder.mutation({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/${id}/update-avatar`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Partner"],
    }),

    // Add a provider
    addProvider: builder.mutation({
      query: ({ partnerId, providerData }) => ({
        url: `${partnerId}/add-provider`,
        method: "POST",
        body: providerData,
      }),
    }),

    // Add a school
    addSchool: builder.mutation({
      query: ({ partnerId, schoolData }) => ({
        url: `${partnerId}/add-school`,
        method: "POST",
        body: schoolData,
      }),
    }),

    // Add a university
    addUniversity: builder.mutation({
      query: ({ partnerId, universityData }) => ({
        url: `${partnerId}/add-university`,
        method: "POST",
        body: universityData,
      }),
    }),

    // Get providers
    getProviders: builder.query({
      query: (partnerId) => `/${partnerId}/providers`,
      providesTags: ["Partner"],
    }),

    // Get schools
    getSchools: builder.query({
      query: (partnerId) => `/${partnerId}/schools`,
      providesTags: ["Partner"],
    }),

    // Get universities
    getUniversities: builder.query({
      query: (partnerId) => `/${partnerId}/universities`,
      providesTags: ["Partner"],
    }),

    // Get a single provider
    getProviderById: builder.query({
      query: (providerId) => `providers/${providerId}`,
      providesTags: ["Partner"],
    }),

    // Get a single school
    getSchoolById: builder.query({
      query: (schoolId) => `schools/${schoolId}`,
      providesTags: ["Partner"],
    }),

    // Get a single university
    getUniversityById: builder.query({
      query: (universityId) => `universities/${universityId}`,
      providesTags: ["Partner"],
    }),

    // Define the endpoint to fetch the assigned admin for a partner by ID
    getAssignedAdminById: builder.query({
      query: (partnerId) => `${partnerId}/assigned-admin`,
      providesTags: ["Partner"],
    }),

    // Get a single school
    getPartnerPayments: builder.query({
      query: (partnerId) => `${partnerId}/schools/payments`,
      providesTags: ["Partner"],
    }),

    // Download Introduction PDF
    downloadIntroductionPdf: builder.query({
      query: ({ partnerId, schoolName }) => ({
        url: `${partnerId}/download-introduction-pdf?schoolName=${schoolName}`,
        method: "GET",
        responseHandler: (response) => response.blob(), // Expecting the response to be a Blob (PDF)
      }),
    }),

    // Define the editPartner mutation
    editPartner: builder.mutation({
      query: ({ partnerId, updatedData }) => ({
        url: `${partnerId}/edit-partner`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["Partner"],
    }),

    // ...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useLoginPartnerMutation,
  useSignupPartnerMutation,
  useUpdateAvatarMutation,
  useAddProviderMutation,
  useAddSchoolMutation,
  useGetProvidersQuery,
  useGetSchoolsQuery,
  useGetProviderByIdQuery,
  useGetSchoolByIdQuery,
  useGetAssignedAdminByIdQuery,
  useAddUniversityMutation,
  useGetUniversitiesQuery,
  useGetUniversityByIdQuery,
  useGetPartnerPaymentsQuery,
  useDownloadIntroductionPdfQuery,
  useEditPartnerMutation,
} = partnerApi;
