import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { useGetActivityStudentsQuery } from "../../../app/services/providerApi";
import { useAppSelector } from "../../../app/hooks";
import { selectProvider } from "../../../app/features/providerSlice";
import google from "../../../assets/images/google-calendar-icon.png";

const ProviderStudentsEnrolled = () => {
  const navigate = useNavigate();
  const { id: providerId } = useAppSelector(selectProvider);
  const { id } = useParams();
  const { data } = useGetActivityStudentsQuery({
    providerId: providerId,
    activityId: id,
  });

  console.log(data);

  const addSchedule = () => {
    navigate("schedule");
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          {data?.map((student: any) => {
            return (
              <SchoolCard key={student._id}>
                <h2>{student.fullName}</h2>
                <p>
                  <strong>Class:</strong> {student.studentClass}
                </p>
                <p>
                  <strong>Gender:</strong> {student.gender}
                </p>
              </SchoolCard>
            );
          })}

          <ScheduleButton onClick={addSchedule}>
            <IconWrapper>
              <img src={google} alt="" />
            </IconWrapper>
            <ButtonText>Grade a session</ButtonText>
          </ScheduleButton>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProviderStudentsEnrolled;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const SchoolCard = styled.div`
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;

  img {
    margin-top: 0.5rem;
  }

  h2,
  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.2rem 0;
  }
`;

const ScheduleButton = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
  border: 1px solid ${colors.grey};
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ButtonText = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.black};
`;
