import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useUpdatePasswordMutation } from "../../../app/services/schoolApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectSchool, setSchool } from "../../../app/features/schoolSlice";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PasswordReset from "../../common/PasswordReset";
import { CustomError } from "../../../types";

const SchoolPasswordReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email } = useAppSelector(selectSchool);
  const [updatePassword, { data, isSuccess, isLoading, isError, error }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Code sent to your email");
      dispatch(
        setSchool({
          id: data.existingSchool._id,
          name: data.existingSchool.name,
          address: data.existingSchool.address,
          email: data.existingSchool.email,
          phoneNo: data.existingSchool.phoneNo,
          website: data.existingSchool.website,
          country: data.existingSchool.country,
          state: data.existingSchool.state,
          lga: data.existingSchool.lga,
          token: data.token,
        })
      );
      navigate("/school-dashboard/home");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <PasswordReset
        isLoading={isLoading}
        updatePassword={updatePassword}
        email={email}
      />
    </Container>
  );
};

export default SchoolPasswordReset;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
