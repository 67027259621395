import React, { useState } from "react";
import {
  useGetAllStudentsQuery,
  useAddStudentsToActivityMutation,
} from "../../../app/services/schoolApi";
import { Button } from "../../FormElements";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { colors } from "../../../utils";
import { useParams } from "react-router-dom";

type StudentOption = {
  value: string;
  label: string;
};

const AddStudentsToActivity = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams();
  const { data: studentsData } = useGetAllStudentsQuery(schoolId);
  const [addStudentsToActivity] = useAddStudentsToActivityMutation();
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  // Map students for displaying in the selection list
  const studentOptions = studentsData?.map((student: any) => ({
    value: student._id,
    label: `${student.fullName}`,
  }));


  const handleStudentSelection = (studentId: string) => {
    if (selectedStudents.includes(studentId)) {
      setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
    } else {
      setSelectedStudents([...selectedStudents, studentId]);
    }
  };

  const handleAddStudents = async () => {
    if (selectedStudents.length > 0) {
      await addStudentsToActivity({
        schoolId,
        activityId,
        studentIds: selectedStudents, // Send the array of student IDs
      });
      setSelectedStudents([]); // Clear selection after successful submission
    }
  };

  return (
    <div>
      <h3>Add Students to Activity</h3>
      <ul style={{ maxHeight: "300px", overflowY: "auto" }}>
        {studentOptions?.map((student: StudentOption) => (
          <li
            key={student.value}
            style={{ display: "flex", alignItems: "center", margin: "5px 0" }}
          >
            <input
              type="checkbox"
              checked={selectedStudents.includes(student.value)}
              onChange={() => handleStudentSelection(student.value)}
            />
            <label style={{ marginLeft: "10px" }}>{student.label}</label>
          </li>
        ))}
      </ul>
      <Button
        text="Add Selected Students"
        color={colors.white}
        backgroundColor={colors.primary}
        border="none"
        onClick={handleAddStudents}
        disabled={selectedStudents.length === 0}
      />
    </div>
  );
};

export default AddStudentsToActivity;
