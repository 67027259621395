import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import GetAdminAttachedToPartner from "./PartnerComponents/GetAdminAttachedToPartner";
import { colors, screens } from "../../utils";
import LearningStats from "./PartnerComponents/LearningStats";
import { IoSchoolOutline } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlinePayments } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import PartnerDashboardCard from "./PartnerComponents/PartnerDashboardCard";
import {
  useGetPartnerPaymentsQuery,
  useGetProvidersQuery,
  useGetSchoolsQuery,
  useGetUniversitiesQuery,
  useDownloadIntroductionPdfQuery,
} from "../../app/services/partnerApi";
import Spinner from "../../utils/Spinner";
import { Button, Input } from "../FormElements";

const PartnerScreens = () => {
  const [schoolName, setSchoolName] = useState("");
  const { firstName, id } = useAppSelector(selectPartner);

  const { data: providersData, isLoading: providerIsLoading } =
    useGetProvidersQuery(id);
  const { data: schoolData, isLoading: schoolIsLoading } =
    useGetSchoolsQuery(id);
  const { data: paymentData } = useGetPartnerPaymentsQuery(id);
  const { data: universityData, isLoading: universityIsLoading } =
    useGetUniversitiesQuery(id);

  // RTK Query for downloading the PDF
  const { data: pdfBlob, isFetching: isDownloading } =
    useDownloadIntroductionPdfQuery(
      { partnerId: id, schoolName },
      { skip: !schoolName }
    );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pdfBlob) {
      // Create a URL for the Blob and trigger a download
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${schoolName}_ExtraBox_Club_Introduction.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <Container>
      <Wrapper>
        <StatsWrapper>
          <h1>Welcome to the dashboard, {firstName}</h1>
          <StatsContent>
            <h3>Your Stats</h3>
            <CardWrapper>
              <PartnerDashboardCard
                linkTo="schools"
                count={
                  schoolIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : (
                    schoolData && schoolData.numberOfSchools
                  )
                }
                text="Schools"
                icon={<IoSchoolOutline size={25} />}
              />
              <PartnerDashboardCard
                linkTo="providers"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : providersData ? (
                    providersData.length
                  ) : (
                    "0"
                  )
                }
                text="Providers"
                icon={<RiCustomerService2Line size={25} />}
              />
              <PartnerDashboardCard
                linkTo="payments"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : paymentData ? (
                    paymentData.totalPayments
                  ) : (
                    "0"
                  )
                }
                text="Payments"
                icon={<MdOutlinePayments size={25} />}
              />
              <PartnerDashboardCard
                linkTo="payments"
                count={
                  providerIsLoading ? (
                    <Spinner color={colors.grey} />
                  ) : paymentData ? (
                    paymentData.totalPayments * 0.1
                  ) : (
                    "0"
                  )
                }
                text="Commissions (10%)"
                icon={<GiMoneyStack size={25} />}
              />
            </CardWrapper>
          </StatsContent>

          <LetterWrapper>
            <h3>Partner Letter</h3>
            <Letter onSubmit={handleSubmit}>
              <Input
                value={schoolName}
                name={schoolName}
                onChange={(e: any) => setSchoolName(e.target.value)}
                placeholder="School Name"
              />
              <Button
                color={colors.white}
                text={isDownloading ? "Printing..." : "Print Letter"} // Indicate download status
                backgroundColor={colors.primary}
                border="none"
                type="submit"
                disabled={!schoolName || isDownloading} // Disable button if no schoolName or downloading
              />
            </Letter>
          </LetterWrapper>

          <StatsContent>
            <h3>Learning Resources</h3>
            <LearningStats />
          </StatsContent>
        </StatsWrapper>
        <RepWrapper>
          <GetAdminAttachedToPartner />
        </RepWrapper>
      </Wrapper>
    </Container>
  );
};

export default PartnerScreens;

// Styled components remain the same
const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const StatsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.7rem;

  &:first-of-type {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 0.9rem;
    color: ${colors.dark};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;

  & > * {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }

  @media (max-width: ${screens.tab}) {
    & > * {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const RepWrapper = styled.div`
  border-left: 1px solid ${colors.primary};
  width: 35%;
  display: flex;

  @media (max-width: ${screens.tab}) {
    border-left: none;
    width: 100%;
  }
`;

const LetterWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: ${colors.lightBack};
  border-radius: 0.25rem;

  h3 {
    font-size: 1rem;
    color: ${colors.black};
  }
`;

const Letter = styled.form`
  display: flex;
  width: 100%;
  gap: 1rem;
`;
