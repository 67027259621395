import React, { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { useUploadBulkStudentsMutation } from "../../../app/services/schoolApi";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import { Button } from "../../FormElements";
import Spinner from "../../../utils/Spinner";

const AddManyStudents = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const [file, setFile] = useState<File | null>(null);
  const [uploadBulkStudents, { isLoading, isSuccess, isError }] =
    useUploadBulkStudentsMutation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        await uploadBulkStudents({ schoolId, file });
        alert("Students uploaded successfully!");
      } catch (err) {
        alert("Failed to upload students.");
      }
    } else {
      alert("Please select a CSV file.");
    }
  };

  return (
    <Container>
      <Wrapper>
        <h2>Upload Bulk Students</h2>

        <SampleWrapper>
          <small>
            This upload accepts only CSV files. Make sure your file is saved
            with the .csv extension
          </small>

          <small>
            Ensure your file is saved with the sample parameters below. It is
            case-sensitive.
          </small>

          <SampleTable>
            <thead>
              <tr>
                <th>fullName</th>
                <th>gender</th>
                <th>studentClass</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>male</td>
                <td>primary5</td>
              </tr>
              <tr>
                <td>Jane Smith</td>
                <td>female</td>
                <td>primary4</td>
              </tr>
            </tbody>
          </SampleTable>
        </SampleWrapper>

        <FormWrapper>
          <small>Format the file and cross check before uploading.</small>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          <ButtonWrapper>
            <Button
              onClick={handleUpload}
              disabled={isLoading}
              text={
                isLoading ? <Spinner color={colors.white} /> : "Upload Students"
              }
              border="none"
              color={colors.white}
              backgroundColor={colors.primary}
            />
          </ButtonWrapper>
          {isSuccess && <p>Students uploaded successfully!</p>}
          {isError && <p>Error uploading students.</p>}
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default AddManyStudents;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  margin: 2rem auto;
`;

const SampleTable = styled.table`
  width: fit-content;
  border-collapse: collapse;
  font-size: 0.8rem;

  th,
  td {
    border: 1px solid ${colors.primary};
    padding: 0.2rem;
    text-align: left;
    font-size: 0.8rem;
  }

  th {
    background-color: transparent;
    font-weight: bold;
  }
`;

const SampleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${colors.blueBack};

  small {
    font-size: 0.8rem;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  small {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
