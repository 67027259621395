import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectProvider, setProvider } from "../../../app/features/providerSlice";
import { useEditProviderMutation } from "../../../app/services/providerApi";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import Form from "../../FormElements/Form";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { useFetchStatesAndLgas } from "../../../hooks/useFetchStatesAndLgas";
import { CustomError } from "../../../types";

const ProviderSettings = () => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(selectProvider);
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas(provider.state);
  
  const [editProvider, { data, isLoading, isSuccess, isError, error }] = useEditProviderMutation();
  const [passwordType, setPasswordType] = useState("password");

  const initialState: FormValues = {
    emailAddress: provider.emailAddress || "",
    firstName: provider.firstName || "",
    lastName: provider.lastName || "",
    phoneNo: provider.phoneNo || "",
    businessName: provider.businessName || "",
    businessAddress: provider.businessAddress || "",
    country: provider.country || "Nigeria",
    state: provider.state || "",
    lga: provider.lga || "",
    password: "",
    confirmPassword: "",
  };

  const { formValues, handleInputChange } = useForm(initialState);
  const {
    emailAddress,
    firstName,
    lastName,
    phoneNo,
    country,
    state,
    lga,
    businessName,
    businessAddress,
    password,
    confirmPassword,
  } = formValues;

  // Toggle Password visibility
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: {
        name: "state",
        value: selectedState,
      },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedData = {
      firstName,
      lastName,
      emailAddress,
      phoneNo,
      country,
      state,
      lga,
      businessName,
      businessAddress,
      password: password || undefined,
    };

    try {
      await editProvider({ providerId: provider.id, updatedData });
    } catch (error) {
      toast.error("Failed to update provider details");
    }
  };

  useEffect(() => {
    if (isSuccess) {
        dispatch(
          setProvider({
            id: data.provider.id,
            firstName: data.provider.firstName,
            lastName: data.provider.lastName,
            emailAddress: data.provider.emailAddress,
            phoneNo: data.provider.phoneNo,
            country: data.provider.country,
            state: data.provider.state,
            lga: data.provider.lga,
            token: data.token,
          })
        );
      toast.success("Profile updated successfully!");
    } else if (isError) {
        const customError = error as CustomError;
        const errorMessage =
          customError?.data?.error || "Oops! Something went wrong";
        toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
        <Wrapper>

        <Heading>Edit your profile</Heading>
        
    <Form onSubmit={handleSubmit}>
      <ItemWrapper>
        <Label text="First Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={firstName}
          name="firstName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Last Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={lastName}
          name="lastName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Email" fontSize="1rem" color={colors.dark} />
        <Input
          type="email"
          value={emailAddress}
          name="emailAddress"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Phone No (WhatsApp Preferred)" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={phoneNo}
          name="phoneNo"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Business Name" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={businessName}
          name="businessName"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Business Address" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={businessAddress}
          name="businessAddress"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Country" fontSize="1rem" color={colors.dark} />
        <Input
          type="text"
          value={country}
          name="country"
          onChange={handleInputChange}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="State" fontSize="1rem" color={colors.dark} />
        <Select
          value={state}
          onChange={handleStateChange}
          name="state"
          defaultOption="Select state"
          options={states.map((state) => ({
            optionValue: state,
            optionText: state,
          }))}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Local Government Area (LGA)" fontSize="1rem" color={colors.dark} />
        <Select
          value={lga}
          onChange={(e: any) => handleInputChange(e)}
          name="lga"
          defaultOption="Select LGA"
          options={lgas.map((lga) => ({
            optionValue: lga,
            optionText: lga,
          }))}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Label text="Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
        <Label text="Confirm Password" fontSize="1rem" color={colors.dark} />
        <PasswordInput
          type={passwordType}
          placeholder="Confirm Password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          onClick={togglePassword}
          passwordType={passwordType}
        />
      </ItemWrapper>
      <Button
        type="submit"
        text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
        color={colors.white}
        backgroundColor={colors.primary}
        border="none"
        disabled={isLoading}
      />
    </Form>
    </Wrapper>
    </Container>
  );
};

export default ProviderSettings;

const Container = styled.div`
    display: flex;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    width: 60%;
    margin: 2rem auto;
    gap: 1rem;
    flex-direction: column;
    display: flex;
    padding: 1rem 4rem;

    @media (max-width: ${screens.tab}) {
        width: 90%;
        padding: 1rem;
        
    }
`

const Heading = styled.h3`
    display: flex;
    font-size: 1.3rem;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
