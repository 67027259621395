import { colors } from "./colors";
import { styled } from "styled-components";
import { RxCaretLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <ProgressWrapper onClick={handleBack}>
      <RxCaretLeft color={colors.secondary} />
      <p>Back</p>
    </ProgressWrapper>
  );
};

export default GoBack;

const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  cursor: pointer;
  align-items: center;

  p {
    font-size: 0.7rem;
    font-weight: 400;
    color: ${colors.dark};
    text-decoration: underline;
    color: ${colors.secondary};
  }
`;
