import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetOneActivityByProviderQuery } from "../../app/services/schoolApi";
import { useAddProviderActivityToSchoolMutation } from "../../app/services/schoolApi";
import { styled } from "styled-components";
import Spinner from "../../utils/Spinner";
import { colors, screens } from "../../utils";
import { useAppSelector } from "../../app/hooks";
import { selectSchool } from "../../app/features/schoolSlice";

const ProviderActivityDetails = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams();
  const navigate = useNavigate();

  const [
    enrollActivity,
    {
      isLoading: isEnrolling,
      isError: isEnrollError,
      isSuccess: isEnrolSuccess,
    },
  ] = useAddProviderActivityToSchoolMutation();

  const {
    data: activity,
    isLoading,
    isError,
  } = useGetOneActivityByProviderQuery(activityId);

  const handleEnroll = async () => {
    try {
      await enrollActivity({ schoolId, activityId });
    } catch (error) {
      console.error("Enrollment failed", error);
    }
  };

  useEffect(() => {
    if (isEnrolSuccess) {
      navigate(
        `/school-dashboard/home/marketplace/activities/${activityId}/enrolled`
      );
    }
  }, [isEnrolSuccess]);

  // Check if the school is already enrolled
  const isSchoolEnrolled = activity?.schools.includes(schoolId);

  return (
    <Container>
      {isLoading ? (
        <Spinner color={colors.primary} />
      ) : isError ? (
        <p>Oops!! Something went wrong</p>
      ) : activity ? (
        <ContentWrapper>
          <ImageWrapper>
            <img src={activity.avatar} alt="" />
          </ImageWrapper>
          <Content>
            <Title>{activity.title}</Title>
            <DescriptionWrapper>
              <h4>Description:</h4>
              <Description
                dangerouslySetInnerHTML={{ __html: activity.description }}
              />
            </DescriptionWrapper>
            <p>
              <strong>Price:</strong>{" "}
              {activity.price.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <ButtonWrapper>
              <EnrollButton
                onClick={handleEnroll}
                disabled={isEnrolling || isSchoolEnrolled}
              >
                {isEnrolling
                  ? "Enrolling..."
                  : isSchoolEnrolled || isEnrolSuccess
                  ? "Already Enrolled"
                  : "Enroll Now"}
              </EnrollButton>
            </ButtonWrapper>
            {isEnrollError && <p>Error enrolling. Please try again.</p>}
          </Content>
        </ContentWrapper>
      ) : (
        <p>Activity not found.</p>
      )}
    </Container>
  );
};

export default ProviderActivityDetails;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 80%;
  gap: 2rem;
  margin: 2rem auto;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  max-height: 20rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${colors.primary};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  color: ${colors.black};
  line-height: 1.5;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.6rem;
  border-top: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};

  h4 {
    font-size: 1rem;
    color: ${colors.black};
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  button {
    padding: 0.7rem 1.5rem;
  }

  select {
    padding: 0.5rem;
    border: 1px solid ${colors.primary};
    border-radius: 0.3rem;
    background-color: white;
  }
`;

const EnrollButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.grey};
  }

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }
`;
