import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import DashboardCard from "./DashboardCard";
import { PiStudent } from "react-icons/pi";
import { MdOutlineLocalActivity } from "react-icons/md";
import { TbCurrencyNaira } from "react-icons/tb";
import { RiParentLine } from "react-icons/ri";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { MdOutlineDevicesFold } from "react-icons/md";
import { colors, screens } from "../../../utils";
import {
  useGetOverviewQuery,
  useGetParentsAddedBySchoolQuery,
  useGetPriceOverviewQuery,
  useDownloadLetterQuery,
  useLazyDownloadLetterQuery, // Import the query for downloading the letter
} from "../../../app/services/schoolApi";
import activitiesImage from "../../../assets/images/schools.jpeg";
import { Button } from "../../FormElements";
import Spinner from "../../../utils/Spinner";
import { getCurriculumsCount } from "../../../lib/firebase-client";
import Modal from "../../Modal";
import UpdateHeadOfSchool from "./UpdateHeadOfSchool";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

const SchoolHome = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { id, name } = useAppSelector(selectSchool);

  // Queries for fetching data
  const { data: overviewData, isLoading: overviewIsLoading } =
    useGetOverviewQuery(id);
  const { data: parentData, isLoading: parentIsLoading } =
    useGetParentsAddedBySchoolQuery(id);
  const { data: paymentData, isLoading: paymentIsLoading } =
    useGetPriceOverviewQuery(id);

  const [curriculumCount, setCurriculumCount] = useState<number | null>(null);
  const [curriculumLoading, setCurriculumLoading] = useState(true);

  // Lazy query hook to trigger the letter download when needed
  const [
    triggerDownloadLetter,
    { data: letterPdf, isFetching: isDownloadingLetter },
  ] = useLazyDownloadLetterQuery();

  const { data, isLoading, error } = useDownloadLetterQuery(id);

  useEffect(() => {
    const fetchCurriculumCount = async () => {
      try {
        const count = await getCurriculumsCount();
        setCurriculumCount(count);
      } catch (error) {
        console.error("Error fetching curriculum count:", error);
      } finally {
        setCurriculumLoading(false);
      }
    };

    fetchCurriculumCount();
  }, []);

  const handleDownloadLetter = async () => {
    try {
      // Make the API request to download the PDF
      const response = await axios.get(
        `https://extrabox-backend.vercel.app/api/schools/${id}/download-letter-pdf`,
        {
          responseType: "blob", // Ensure the response is a blob (binary data)
        }
      );

      // Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.setAttribute("download", `${id}-WelcomeLetter.pdf`); // Set the file name

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Wait for 3 seconds before triggering the download
      setTimeout(() => {
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up after download
      }, 3000); // Delay for 3 seconds
    } catch (err) {
      console.error("Failed to download the letter:", err);
    }
  };

  const handleMarketplace = () => {
    navigate("marketplace");
  };

  return (
    <Container>
      <Wrapper>
        <TopWrapper>
          <h1>Welcome back, {name}</h1>
          <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <UpdateHeadOfSchool closeModal={() => setIsOpen(false)} />
          </Modal>
          <ButtonContainer>
            <ButtonWrapper>
              <Button
                text="Add Principal"
                color={colors.white}
                backgroundColor={colors.primary}
                border="none"
                onClick={() => setIsOpen(true)}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                text={
                  isDownloadingLetter
                    ? "Downloading..."
                    : "Download Welcome Letter"
                }
                color={colors.white}
                backgroundColor={colors.primary}
                border="none"
                onClick={handleDownloadLetter}
                disabled={isDownloadingLetter}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </TopWrapper>

        {/* Other content like cards and activity overview */}
        <CardWrapper>
          <DashboardCard
            linkTo="students"
            count={
              overviewIsLoading ? (
                <Spinner color={colors.grey} />
              ) : overviewData ? (
                `${overviewData.totalStudentsInActivities} / ${overviewData.totalStudents}`
              ) : (
                0
              )
            }
            text="Students enrolled/onboarded"
            icon={<PiStudent size={25} />}
          />
          <DashboardCard
            linkTo="activities"
            count={
              overviewIsLoading ? (
                <Spinner color={colors.grey} />
              ) : overviewData ? (
                `${overviewData.inhouse} / ${overviewData.external}`
              ) : (
                0
              )
            }
            text="InHouse/Providers Activities"
            icon={<MdOutlineLocalActivity size={25} />}
          />
          <DashboardCard
            linkTo="parents"
            count={
              parentData ? (
                parentData.length
              ) : parentIsLoading ? (
                <Spinner color={colors.grey} />
              ) : (
                0
              )
            }
            text="Parents onboarded"
            icon={<RiParentLine size={25} />}
          />
          <DashboardCard
            linkTo="payments"
            count={
              paymentData ? (
                `${paymentData.sumPaymentsMade} / ${
                  paymentData.sumUnpaidProviderPayments +
                  paymentData.sumUnpaidDefaultPayments
                }`
              ) : paymentIsLoading ? (
                <Spinner color={colors.grey} />
              ) : (
                0
              )
            }
            text="Paid / Unpaid"
            icon={<TbCurrencyNaira size={25} />}
          />
          <DashboardCard
            linkTo="activities/rent-a-gadget"
            count={5}
            text="RentAGadget"
            icon={<MdOutlineDevicesFold size={25} />}
          />
          <DashboardCard
            linkTo="home/curriculum"
            count={
              curriculumLoading ? (
                <Spinner color={colors.grey} />
              ) : curriculumCount !== null ? (
                curriculumCount
              ) : (
                0
              )
            }
            text="Exam Curriculum"
            icon={<MdOutlineLibraryBooks size={25} />}
          />
        </CardWrapper>

        <ContentWrapper>
          <TextContent>
            <h3>Browse Activities</h3>
            <p>
              Find activities your students are interested in and win amazing
              rewards
            </p>
            <ButtonWrapper>
              <Button
                text="Button to Marketplace"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                onClick={handleMarketplace}
              />
            </ButtonWrapper>
          </TextContent>
          <ImageWrapper>
            <img src={activitiesImage} alt="School activities" />
          </ImageWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default SchoolHome;

// Styled components and CSS rules as before

const Container = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  & > * {
    flex: 1 1 calc(33.333% - 1.5rem);
    max-width: calc(33.333% - 1.5rem);
  }

  @media (max-width: ${screens.tab}) {
    flex-direction: row;
    & > * {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  gap: 2rem;
  margin: 1rem auto;
  background-color: ${colors.blueLight};
  height: fit-content;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
    gap: 1rem;
  }
`;
