import React from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import {
  useGetAllAdminActivitiesQuery,
  useGetAllAdminsQuery,
  useGetAllPartnersQuery,
  useGetAllProvidersQuery,
  useGetAllSchoolsQuery,
  useGetAllUniversitiesQuery,
} from "../../../app/services/adminApi";

const AdminStats = () => {
  const {
    data: adminData,
    isError: adminIsError,
    isSuccess: adminIsSuccess,
    isLoading: adminIsLoading,
  } = useGetAllAdminsQuery({});
  const {
    data: providersData,
    isError: providersIsError,
    isSuccess: providersIsSuccess,
    isLoading: providersIsLoading,
  } = useGetAllProvidersQuery({});

  const {
    data: schoolsData,
    isError: schoolsIsError,
    isSuccess: schoolsIsSuccess,
    isLoading: schoolsIsLoading,
  } = useGetAllSchoolsQuery({});
  const {
    data: partnersData,
    isError: partnersIsError,
    isSuccess: partnersIsSuccess,
    isLoading: partnersIsLoading,
  } = useGetAllPartnersQuery({});

  const {
    data: activityData,
    isError: activitiesIsError,
    isSuccess: activitiesIsSuccess,
    isLoading: activitiesIsLoading,
  } = useGetAllAdminActivitiesQuery({});

  return (
    <Container>
      <Wrapper>
        <h3>Onboarded Stats</h3>
        <Content>
          <ContentWrapper>
            <small>Admins</small>
            <h3>{adminData && adminData.length}</h3>
          </ContentWrapper>
          <ContentWrapper>
            <small>Partners</small>
            <h3>{partnersData && partnersData.length}</h3>
          </ContentWrapper>
          <ContentWrapper>
            <small>Schools</small>
            <h3>{schoolsData && schoolsData.length}</h3>
          </ContentWrapper>
          <ContentWrapper>
            <small>Providers</small>
            <h3>{providersData && providersData.length}</h3>
          </ContentWrapper>
          <ContentWrapper>
            <small>Activities</small>
            <h3>{activityData && activityData.length}</h3>
          </ContentWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default AdminStats;

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.bluePry};
  border-radius: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 1.5rem;
  gap: 1rem;
  align-items: center;

  h3 {
    font-size: 0.9rem;
    color: ${colors.white};
    font-weight: 700;
  }

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    flex-wrap: wrap;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-right: 1px solid ${colors.white};

  &:last-of-type {
    border-right: none;
  }

  @media (max-width: ${screens.tab}) {
    border-right: none;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    color: ${colors.white} !important;
  }

  small {
    font-size: 0.9rem;
    font-weight: 400;
    color: ${colors.white};
    line-height: 1.5;
  }
`;
