import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useUpdatePasswordMutation } from "../../../app/services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectProvider,
  setProvider,
} from "../../../app/features/providerSlice";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PasswordReset from "../../common/PasswordReset";
import { CustomError } from "../../../types";

const ProviderPasswordReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { emailAddress } = useAppSelector(selectProvider);
  const [updatePassword, { data, isSuccess, isLoading, isError, error }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Code sent to your email");
      dispatch(
        setProvider({
          id: data.existingProvider._id,
          firstName: data.existingProvider.firstName,
          lastName: data.existingProvider.lastName,
          emailAddress: data.existingProvider.emailAddress,
          phoneNo: data.existingProvider.phoneNo,
          businessName: data.existingProvider.businessName,
          businessAddress: data.existingProvider.businessAddress,
          token: data.token,
        })
      );
      navigate("/provider-dashboard/home");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess]);

  return (
    <Container>
      <PasswordReset
        isLoading={isLoading}
        updatePassword={updatePassword}
        email={emailAddress}
      />
    </Container>
  );
};

export default ProviderPasswordReset;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
