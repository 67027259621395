import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { selectAdmin } from "../../../../app/features/adminSlice";
import { useAppSelector } from "../../../../app/hooks";
import {
  useApproveActivityMutation,
  useDeleteActivityAdminByIdMutation,
  useGetActivityAdminByIdQuery,
} from "../../../../app/services/adminApi";
import Spinner from "../../../../utils/Spinner";
import { colors, screens } from "../../../../utils";
import { styled } from "styled-components";
import { Button } from "../../../FormElements";
import { toast } from "react-hot-toast";

const AdminActivityDetails = () => {
  const { id: adminId } = useAppSelector(selectAdmin);
  const { id: activityId } = useParams();
  const navigate = useNavigate();

  const [
    deleteActivity,
    {
      isLoading: isDeleting,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteActivityAdminByIdMutation();

  const [
    approveActivity,
    {
      isLoading: isApproving,
      isError: isApproveError,
      isSuccess: isApproveSuccess,
    },
  ] = useApproveActivityMutation();

  const {
    data: activity,
    isLoading,
    isError,
  } = useGetActivityAdminByIdQuery(activityId);

  const handleDelete = async () => {
    try {
      await deleteActivity(activityId);
    } catch (error) {
      console.error("Enrollment failed", error);
    }
  };

  const handleApprove = async () => {
    try {
      approveActivity(activityId);
    } catch (error) {
      toast.error("Approval failed");
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      navigate(`/admin-dashboard/activities`);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isApproveSuccess) {
      navigate(`/admin-dashboard/activities`);
    }
  }, [isApproveSuccess]);

  return (
    <Container>
      {isLoading ? (
        <Spinner color={colors.primary} />
      ) : isError ? (
        <p>Oops!! Something went wrong</p>
      ) : activity ? (
        <ContentWrapper>
          <ImageWrapper>
            <img src={activity.avatar} alt="" />
          </ImageWrapper>
          <Content>
            <Title>{activity.title}</Title>
            <DescriptionWrapper>
              <h4>Description:</h4>
              <Description
                dangerouslySetInnerHTML={{ __html: activity.description }}
              />
            </DescriptionWrapper>
            <p>
              <strong>Price:</strong>{" "}
              {Number(activity.price).toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>

            <ButtonContainer>
              <ButtonWrapper>
                <Button
                  backgroundColor={colors.red}
                  color={colors.white}
                  border="none"
                  text={
                    isDeleting ? <Spinner color={colors.white} /> : "Delete Now"
                  }
                  disabled={isDeleting}
                  onClick={handleDelete}
                />
              </ButtonWrapper>
              {activity.isApproved === false ? (
                <ButtonWrapper>
                  <Button
                    backgroundColor={colors.green}
                    color={colors.white}
                    border="none"
                    text={
                      isApproving ? (
                        <Spinner color={colors.white} />
                      ) : (
                        "Approve Now"
                      )
                    }
                    disabled={isApproving}
                    onClick={handleApprove}
                  />
                </ButtonWrapper>
              ) : null}
            </ButtonContainer>
            {isDeleteError && <p>Error Deleting. Please try again.</p>}
          </Content>
        </ContentWrapper>
      ) : (
        <p>Activity not found.</p>
      )}
    </Container>
  );
};

export default AdminActivityDetails;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 80%;
  gap: 2rem;
  margin: 2rem auto;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  max-height: 20rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${colors.primary};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  color: ${colors.black};
  line-height: 1.5;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.6rem;
  border-top: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};

  h4 {
    font-size: 1rem;
    color: ${colors.black};
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
