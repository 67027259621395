import React from "react";
import { styled } from "styled-components";
import { colors } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectProvider } from "../../../app/features/providerSlice";
import { useGetProviderActivitiesStudentsQuery } from "../../../app/services/providerApi";
import { PiTagChevronThin } from "react-icons/pi";
import { TiLocationArrowOutline } from "react-icons/ti";
import { MdPersonOutline } from "react-icons/md";

const ProviderStudents = () => {
  const { id } = useAppSelector(selectProvider);
  const { data } = useGetProviderActivitiesStudentsQuery(id);

  return (
    <ContentWrapper>
      <TopWrapper>
        <h3>Provider Students List</h3>
      </TopWrapper>

      <Content>
        {data?.map((student: any) => {
          return (
            <StudentContainer key={student._id}>
              <StudentDetails>
                <NameWrapper>
                <AvatarWrapper>
                  <img src={student.avatar} alt={student.fullName} />
                </AvatarWrapper>
                <StudentInfo>
                  <PiTagChevronThin size="0.8rem" color={colors.dark} />
                  <p>{student.fullName}</p>
                </StudentInfo>
                </NameWrapper>

                <StudentMode>
                  <IconWrapper>
                    <TiLocationArrowOutline size="0.9rem" color={colors.dark} />
                  </IconWrapper>
                  <p>{student.studentClass}</p>
                </StudentMode>

                <StudentMode>
                  <IconWrapper>
                    <MdPersonOutline size="0.9rem" color={colors.dark} />
                  </IconWrapper>
                  <p>{student.gender}</p>
                </StudentMode>
              </StudentDetails>
            </StudentContainer>
          );
        })}
      </Content>
    </ContentWrapper>
  );
};

export default ProviderStudents;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StudentContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 0.5px solid ${colors.grey};
  border-bottom: 0.5px solid ${colors.grey};

  &:hover {
    border: 0.5px solid ${colors.grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const NameWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
`

const StudentDetails = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  margin: 0.2rem;
  justify-content: center;
`;

const StudentInfo = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    text-transform: capitalize;
    word-wrap: break-word;
  }
`;

const StudentMode = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  gap: 0.4rem;

  p {
    display: flex;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${colors.dark};
    text-transform: capitalize;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const AvatarWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border: 2px solid ${colors.grey};
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;
