import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectProvider } from "../../../app/features/providerSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOneActivityQuery } from "../../../app/services/providerApi";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import { styled } from "styled-components";
import scheduleImg from "../../../assets/images/google-calendar-icon.png";
import { Button } from "../../FormElements";

const ProviderSingleActivity = () => {
  const navigate = useNavigate();
  const { id: providerId } = useAppSelector(selectProvider);
  const { id: activityId } = useParams();

  const { data, isLoading, isError, error } = useGetOneActivityQuery({
    activityId,
    providerId,
  });

  const handleEdit = () => {
    navigate(`edit`);
  };

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.primary} />
        ) : isError ? (
          <p>Oops! Something went wrong.</p>
        ) : (
          <ContentWrapper>
            <ImageWrapper>
              <img src={data.avatar} alt="" />
            </ImageWrapper>
            <Content>
              <EditButtonWrapper>
                <Button
                  text="Edit Activity"
                  backgroundColor={colors.green}
                  border="none"
                  color={colors.white}
                  onClick={handleEdit}
                />
              </EditButtonWrapper>
              <Title>{data?.title}</Title>
              <DescriptionWrapper>
                <h4>Description:</h4>
                <Description
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </DescriptionWrapper>
              <DataWrapper>
                <DataHeading>Schools Enrolled:</DataHeading>
                <DataDetails>{data?.schools?.length}</DataDetails>
                <StudentsWrapper to={`${data?._id}/schools`}>
                  View
                </StudentsWrapper>
              </DataWrapper>
              <DataWrapper>
                <DataHeading>Students Enrolled:</DataHeading>
                <DataDetails>{data?.students?.length}</DataDetails>
                <StudentsWrapper to={`/provider-dashboard/schools`}>
                  View
                </StudentsWrapper>
              </DataWrapper>

              <ButtonContainer>
                {data?.isApproved === true &&
                data?.schools?.length >= 1 &&
                data?.students?.length >= 1 ? (
                  <ScheduleButton to={`${data?._id}/schools/schedule`}>
                    <img src={scheduleImg} alt="" />
                    <p>Schedule Session</p>
                  </ScheduleButton>
                ) : null}

                {data?.schedules.length === 0 ? null : (
                  <ButtonWrapper>
                    <RegisterMark to={`register`}>Mark Register</RegisterMark>
                  </ButtonWrapper>
                )}
              </ButtonContainer>
            </Content>
          </ContentWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default ProviderSingleActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  max-height: 25rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  color: ${colors.black};
  line-height: 1.5;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.6rem;
  border-top: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};

  h4 {
    font-size: 1rem;
    color: ${colors.black};
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.1fr 0.4rem;
  gap: 0.5rem;
`;

const DataHeading = styled.h4`
  font-size: 1rem;
  color: ${colors.black};
  font-weight: 600;
`;

const DataDetails = styled.p`
  font-size: 1rem;
  color: ${colors.black};
`;

const StudentsWrapper = styled(Link)`
  display: flex;
  color: ${colors.dark};
  text-decoration-color: ${colors.dark};
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ScheduleButton = styled(Link)`
  display: flex;
  width: fit-content;
  gap: 0.4rem;
  text-decoration: none;
  border-radius: 0.5rem;
  border: 1px solid ${colors.primary};
  padding: 0.1rem 0.5rem;
  align-items: center;

  img {
    width: 2rem;
    object-fit: contain;
    margin: auto;
  }

  p {
    font-size: 0.85rem;
    color: ${colors.black};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  button {
    padding: 0.7rem 1.5rem;
  }

  select {
    padding: 0.5rem;
    border: 1px solid ${colors.primary};
    border-radius: 0.3rem;
    background-color: white;
  }
`;

const RegisterMark = styled(Link)`
  display: flex;
  width: fit-content;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 0.6rem 1rem;
  font-size: 0.85rem;
  font-weight: 700;
  background-color: ${colors.primary};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.dark};
  }
`;

const EditButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
