import React from "react";
import { selectProvider } from "../../../app/features/providerSlice";
import { useAppSelector } from "../../../app/hooks";
import { useGetProviderActivitiesSchoolsQuery } from "../../../app/services/providerApi";
import { styled } from "styled-components";
import { colors } from "../../../utils";

const ProviderSchoolsList = () => {
  const { id } = useAppSelector(selectProvider);
  const { data } = useGetProviderActivitiesSchoolsQuery(id);

  return (
    <ContentWrapper>
      <TopWrapper>
        <h3>Provider Schools List</h3>
      </TopWrapper>
      <Content>
        {data?.map((school: any) => {
          return (
            <div key={school._id}>
              <p>{school.name}</p>
              <p>{school.address}</p>
            </div>
          );
        })}
      </Content>
    </ContentWrapper>
  );
};

export default ProviderSchoolsList;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
