import React, { useCallback, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import {
  useGetProvidersForPaymentsQuery,
  usePayForProviderMutation,
} from "../../../app/services/schoolApi";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import ProviderPaymentCard from "./ProviderPaymentCard";
import { Button } from "../../FormElements";
import { toast } from "react-hot-toast";

const SchoolProviderPayments = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { data, isLoading, isError } =
    useGetProvidersForPaymentsQuery(schoolId);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");

  const [payForProvider, { isLoading: payIsLoading }] =
    usePayForProviderMutation();

  const selectedPayment = data?.find(
    (item: any) => item.id === selectedPaymentId
  );

  const studentCount = selectedPayment?.studentsEnrolled.length || 0;
  const totalPrice = studentCount * (selectedPayment?.price || 0);

  const handlePayment = async () => {
    if (schoolId && studentCount && selectedPayment) {
      const response = await payForProvider({
        schoolId,
        activityId: selectedPayment.id,
        studentsNo: studentCount,
      });

      // Check if the response is an error
      if ("error" in response) {
        toast.error("No payment link. Try again");
        return;
      }

      if (response.data?.authorizationUrl) {
        setIsRedirecting(true);
        window.location.href = response.data.authorizationUrl;
      } else {
        toast.error("No payment link. Try again");
      }
    } else {
      toast.error(
        "Missing schoolId, studentCount, term, or selected payment. Try again"
      );
    }
  };

  const handlePaymentCardClick = useCallback((paymentId: any) => {
    setSelectedPaymentId(paymentId);
  }, []);

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <PaymentsWrapper>
            {isLoading ? (
              <Spinner color={colors.primary} />
            ) : isError ? (
              <p>Oops! Something went wrong..</p>
            ) : data?.length > 0 ? (
              data.map((provider: any) => (
                <ProviderPaymentCard
                  key={provider.id}
                  title={provider.title}
                  price={provider.price}
                  studentsEnrolled={provider.studentsEnrolled.length}
                  isPaid={provider.isPaid || "not-paid"}
                  onClick={() => handlePaymentCardClick(provider.id)}
                  isSelected={provider.id === selectedPaymentId}
                />
              ))
            ) : (
              <p>No provider payments found</p>
            )}
          </PaymentsWrapper>
        </ContentWrapper>

        <Aside>
          <h3>Make a Payment</h3>
          <p>No of students: {studentCount}</p>
          <p>Unit price per student: ₦{selectedPayment?.price || 0}</p>

          <ItemWrapper>
            <Button
              text={
                payIsLoading ? (
                  <Spinner color={colors.white} />
                ) : (
                  `Pay total of ₦${totalPrice}`
                )
              }
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
              onClick={handlePayment}
              disabled={!selectedPayment}
            />
          </ItemWrapper>
        </Aside>
      </Wrapper>
    </Container>
  );
};

export default SchoolProviderPayments;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const PaymentsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Aside = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 0.5rem;
  border-left: 1px solid ${colors.primary};
  padding: 1rem 0 1rem 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
`;
