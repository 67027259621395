import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { selectAdmin } from "../../../app/features/adminSlice";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../FormElements";
import { colors, screens } from "../../../utils";
import AddAdmin from "./AddAdmin";
import Modal from "../../Modal";
import AllProviders from "./AllProviders";
import AllAdmins from "./AllAdmins";
import AllPartners from "./AllPartners";
import AllSchools from "./AllSchools";
import TabsWrapper from "../../common/TabsWrapper";
import AdminLearningStats from "../AdminComponents/AdminLearningStats";
import AdminStats from "../AdminComponents/AdminStats";
import {
  useDefaultPaymentMutation,
  useGetDefaultPriceQuery,
  useGetPaymentsSumQuery,
} from "../../../app/services/adminApi";
import AllAdminActivities from "./AdminActivities/AllAdminActivities";

const tabItems = ["Admins", "Partners", "Providers", "Schools", "Activities"];

const termOptions = [
  { optionValue: "first-term", optionText: "First Term" },
  { optionValue: "second-term", optionText: "Second Term" },
  { optionValue: "third-term", optionText: "Third Term" },
];

const AdminScreens = () => {
  const { firstName, lastName, email, phoneNo, tag } =
    useAppSelector(selectAdmin);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });

  const { data: totalPaid } = useGetPaymentsSumQuery({});

  const { data: defaultPriceData, refetch: refetchDefaultPrice } =
    useGetDefaultPriceQuery({});
  const [setDefaultPrice, { isLoading: pricingIsLoading }] =
    useDefaultPaymentMutation();
  const [price, setPrice] = useState<number>();
  const [term, setTerm] = useState("");
  const [year, setYear] = useState<string>("");

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <AllAdmins />;
      case tabItems[1]:
        return <AllPartners />;
      case tabItems[2]:
        return <AllProviders />;
      case tabItems[3]:
        return <AllSchools />;
      case tabItems[4]:
        return <AllAdminActivities />;
      default:
        return <AllAdmins />;
    }
  };

  const renderNavItems = () => {
    return (
      <TabList className="sub-nav">
        {tabItems.map((item) => (
          <li
            key={item}
            className={item === tab ? "selected" : ""}
            onClick={() => setTab(item)}
          >
            {item}
          </li>
        ))}
      </TabList>
    );
  };

  const handleAddAdmin = () => {
    setIsOpen(true);
  };

  const handleSetDefaultPayment = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    await setDefaultPrice({ price, year, term });
    refetchDefaultPrice();
    setPrice(undefined);
    setTerm("");
    setYear("");
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h1>Welcome to the dashboard, {firstName}</h1>

          <TabsWrapper renderNavItems={renderNavItems}>
            <TabsContent>{renderChildren()}</TabsContent>
          </TabsWrapper>
        </ContentWrapper>

        <Aside>
          {(tag === "ceo" || tag === "manager") && (
            <AddAdminWrapper>
              <h3>Add a New Admin</h3>
              <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <AddAdmin closeModal={() => setIsOpen(false)} />
              </Modal>
              <Button
                type="button"
                color={colors.white}
                backgroundColor={colors.primary}
                border="none"
                onClick={handleAddAdmin}
                text="Add an admin"
              />
            </AddAdminWrapper>
          )}

          {/* {
            "termWiseAmounts": [
                {
                    "totalAmount": 4000,
                    "year": "2024",
                    "term": "first-term"
                }
                {
                    "totalAmount": 4800,
                    "year": "2024",
                    "term": "second-term"
                }
            ]
            "totalOverallAmount": 8800
        } */}

          {tag === "ceo" ? (
            <PaymentWrapper>
              <h3>Payments Summary</h3>
              {totalPaid ? (
                <Payments>
                  <TotalPayments>
                    <strong>Total Paid: </strong>
                    <p>
                      {totalPaid.totalOverallAmount.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </TotalPayments>
                  {totalPaid.termWiseAmounts?.map((term: any, index: any) => {
                    return (
                      <Payment key={index}>
                        <strong>
                          {term.term} {term.year}:
                        </strong>
                        <p>
                          {term.totalAmount.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </p>
                      </Payment>
                    );
                  })}
                </Payments>
              ) : null}
            </PaymentWrapper>
          ) : null}
        </Aside>
      </Wrapper>

      <Wrapper>
        <AdminStats />
        <AdminLearningStats />
      </Wrapper>
    </Container>
  );
};

export default AdminScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Aside = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 2rem;
  border-left: 1px solid ${colors.primary};
  padding: 1rem 0 1rem 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }
`;

const AddAdminWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.dark};
  }
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.primary};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  height: 40vh;
  overflow: scroll;
  padding-bottom: 2rem;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
`;

const PaymentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
    padding: 0;
    border-left: none;
  }

  h3 {
    font-size: 1rem;
    font-weight: 700;
    color: ${colors.dark};
  }
`;

const Payments = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;

const TotalPayments = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;

  strong {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }
`;

const Payment = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.2rem;
  padding: 0.5rem;
  background-color: ${colors.blueBack};
  gap: 0.3rem;

  strong {
    font-size: 0.9rem;
    text-transform: capitalize;
  }

  p {
    font-size: 0.9rem;
    text-transform: capitalize;
  }
`;
