import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useGetActivityByIdQuery,
  useGetAllStudentsQuery,
  useAddStudentToActivityMutation,
  useAddGradingToActivityMutation,
} from "../../../app/services/schoolApi";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import Select from "react-select";
import Modal from "../../Modal";
import Session from "./Session";
import Pagination from "../../common/Pagination";
import { PiTagChevronThin } from "react-icons/pi";
import AddCoach from "./AddCoach";
import { Button } from "../../FormElements";

const ITEMS_PER_PAGE = 10;

type StudentOption = {
  value: string;
  label: string;
};

const SingleActivity = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCoachOpen, setIsCoachOpen] = useState<boolean>(false);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState<boolean>(false);
  const [isGradingModalOpen, setIsGradingModalOpen] = useState<boolean>(false);
  const [currentStudentId, setCurrentStudentId] = useState<string | null>(null);
  const {
    data: activityData,
    isLoading,
    isError,
    refetch,
  } = useGetActivityByIdQuery({
    activityId,
    schoolId,
  });
  const { data: studentsData } = useGetAllStudentsQuery(schoolId);
  const [addStudentToActivity] = useAddStudentToActivityMutation();
  const [addGradingToActivity] = useAddGradingToActivityMutation();
  const [selectedStudent, setSelectedStudent] = useState<StudentOption | null>(
    null
  );
  const [gradingParameters, setGradingParameters] = useState("");

  const [page, setPage] = useState(1);
  const [studentsToDisplay, setStudentsToDisplay] = useState<any[]>([]);

  useEffect(() => {
    if (activityData?.students && studentsData) {
      // Get students enrolled in this activity and also in the current school
      const enrolledStudentsFromSchool = activityData.students.filter(
        (student: any) =>
          studentsData.some(
            (s: any) => s._id === student._id && s.school === schoolId
          )
      );

      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;

      // Set only students from the specified school in `studentsToDisplay`
      setStudentsToDisplay(
        enrolledStudentsFromSchool.slice(startIndex, endIndex)
      );
    }
  }, [activityData, studentsData, page, schoolId]);

  const handleAddSession = (studentId: string) => {
    setCurrentStudentId(studentId);
    setIsOpen(true);
  };

  const handleAddCoach = () => {
    setIsCoachOpen(true);
  };

  const handleAddStudent = async () => {
    if (selectedStudent) {
      await addStudentToActivity({
        schoolId,
        activityId,
        studentId: selectedStudent.value,
      });
      refetch();
    }
  };

  const studentOptions = studentsData?.map((student: any) => ({
    value: student._id,
    label: `${student.fullName}`,
  }));

  const handleGradingParameters = () => {
    setIsGradingModalOpen(true);
  };

  const submitGradingParameters = async () => {
    await addGradingToActivity({
      schoolId,
      activityId,
      sessionNo: gradingParameters,
    });
    setIsGradingModalOpen(false);
    refetch();
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Container>
      <Wrapper>
        <TopWrapper>
          <h3>Activity Details</h3>
          {activityData?.creatorType === "School" ? (
            <>
              <Modal open={isCoachOpen} onClose={() => setIsCoachOpen(false)}>
                <AddCoach closeModal={() => setIsCoachOpen(false)} />
              </Modal>
              <BtnWrapper>
                <Button
                  text="Add Coach"
                  color={colors.white}
                  backgroundColor={colors.primary}
                  border="none"
                  onClick={handleAddCoach}
                />
              </BtnWrapper>
            </>
          ) : null}
        </TopWrapper>

        <small>
          View activity details here. Add students. Manage sessions.
        </small>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : isError ? (
          <p>Something went wrong!</p>
        ) : activityData ? (
          <ContentWrapper>
            <TopWrapper>
              {activityData?.creatorType ===
              "Provider" ? null : activityData?.creatorType === "School" ? (
                <ButtonWrapper>
                  <ScheduleLink to={`create-schedule`}>
                    Add a Session
                  </ScheduleLink>
                </ButtonWrapper>
              ) : (
                <GradingWrapper onClick={handleGradingParameters}>
                  <p>Add Grading Parameters</p>
                </GradingWrapper>
              )}
              {activityData?.schedules.length === 0 ? (
                <ButtonWrapper>
                  <ScheduleLink to={`create-schedule`}>
                    Add a Session
                  </ScheduleLink>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <RegisterMark to={`register`}>Mark Register</RegisterMark>
                  
                  <RegisterMark to={`add-students`}>Bulk Add Students</RegisterMark>
                </ButtonWrapper>
              )}
            </TopWrapper>

            <ItemWrapper>
              <strong>Title: </strong>
              <p>{activityData.title}</p>
            </ItemWrapper>
            <ItemWrapper>
              <strong>Description: </strong>
              <Description
                dangerouslySetInnerHTML={{ __html: activityData.description }}
              />
            </ItemWrapper>
            <ItemWrapper>
              <strong>Mode: </strong>
              <p>{activityData.mode}</p>
            </ItemWrapper>
            <SessionC>
              <strong>No of Sessions: </strong>
              <p>{activityData.schedules.length}</p>
              {activityData.schedules.length > 0 && (
                <SessionLink to={`/school-dashboard/schedules/${activityId}`}>
                  See Sessions
                </SessionLink>
              )}
            </SessionC>

            <ActivitiesContent>
              <Content>
                <TopWrapper>
                  <h3>Students enrolled for this activity</h3>
                  <ButtonWrapper>
                    <Select
                      options={studentOptions}
                      value={selectedStudent}
                      onChange={setSelectedStudent}
                      placeholder="Select a student"
                    />
                    <StyledButton
                      onClick={handleAddStudent}
                      disabled={!selectedStudent}
                    >
                      Enroll Student
                    </StyledButton>
                  </ButtonWrapper>
                </TopWrapper>

                {studentsToDisplay.map((student: any) => {
                  return (
                    <StudentInSession key={student._id}>
                      <h5>
                        <PiTagChevronThin size="0.8rem" color={colors.dark} />
                        {student.fullName}
                      </h5>
                    </StudentInSession>
                  );
                })}

                <Pagination
                  currentPage={page}
                  totalItems={activityData.students.length}
                  itemsPerPage={ITEMS_PER_PAGE}
                  onPageChange={handlePageChange}
                />
              </Content>
            </ActivitiesContent>
          </ContentWrapper>
        ) : (
          <p>No activity data found!</p>
        )}
        <SessionWrapper>
          <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            {currentStudentId && (
              <Session
                closeModal={() => setIsOpen(false)}
                activity={activityId}
                student={currentStudentId}
              />
            )}
          </Modal>
        </SessionWrapper>

        <GradingModalWrapper>
          <Modal
            open={isGradingModalOpen}
            onClose={() => setIsGradingModalOpen(false)}
          >
            <GradingModalContent>
              <h3>Enter Grading Parameters</h3>
              <input
                type="text"
                value={gradingParameters}
                onChange={(e) => setGradingParameters(e.target.value)}
                placeholder="Enter grading parameters"
              />
              <StyledButton onClick={submitGradingParameters}>
                Submit
              </StyledButton>
            </GradingModalContent>
          </Modal>
        </GradingModalWrapper>
      </Wrapper>
    </Container>
  );
};

export default SingleActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 2rem auto;

  h3 {
    font-size: 1.2rem;
  }

  small {
    font-size: 0.7rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    padding: 2rem 0;
  }

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1.5fr;
  width: 100%;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 0.5fr 1fr;
  }

  p {
    font-weight: 500;
    font-size: 0.85rem;
  }

  strong {
    font-size: 0.85rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    padding: 0.7rem 1.5rem;
  }

  select {
    padding: 0.5rem;
    border: 1px solid ${colors.primary};
    border-radius: 0.3rem;
    background-color: white;
  }
`;

const StyledButton = styled.button`
  padding: 0.6rem 1rem;
  font-size: 0.85rem;
  font-weight: 700;
  color: ${colors.white};
  background-color: ${colors.primary};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.dark};
  }
`;

const StudentInSession = styled.div`
  padding: 0.5rem 0.8rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h5 {
    font-size: 0.85rem;
    font-weight: 500;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ActivitiesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const SessionWrapper = styled.div`
  width: 100%;
`;

const GradingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${colors.lightBack};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const GradingModalWrapper = styled.div`
  width: 100%;
`;

const GradingModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RegisterMark = styled(Link)`
  display: flex;
  width: 100%;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 0.6rem 1rem;
  font-size: 0.85rem;
  font-weight: 700;
  background-color: ${colors.primary};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:disabled {
    background-color: ${colors.grey};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.dark};
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;

const ScheduleLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  padding: 0.5rem;
  background-color: ${colors.primary};
  cursor: pointer;
  border-radius: 0.25rem;
`;

const SessionLink = styled(Link)`
  display: flex;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: ${colors.white};
  background-color: ${colors.primary};
  cursor: pointer;
`;

const SessionC = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;

  p {
    font-weight: 500;
    font-size: 0.85rem;
  }

  strong {
    font-size: 0.85rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;
