import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../utils";
import ActivityCard from "../ActivityCard";
import Pagination from "../common/Pagination";
import SearchBox from "../SearchBox";

const SearchResults = () => {
  // Get the search results from the location state
  const location = useLocation();
  const { filteredProducts, query } = location.state || {
    filteredProducts: [],
    query: "",
  };

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Calculate total items and pages
  const totalItems = filteredProducts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get products for the current page
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <SearchResultsContainer>
      <SearchWrapper>
        <SearchBox dashboardType="school" />
      </SearchWrapper>

      <h3>Search: {query}</h3>
      {currentProducts.length ? (
        <>
          <ProductsGrid>
            {currentProducts.map((activity: any) => (
              <ActivityCard
                key={activity._id}
                title={activity.title}
                mode={activity.mode}
                studentsCount={activity.schools.length}
                src={activity.avatar}
                bgCol={colors.white}
                titleCol={colors.black}
                price={activity.price}
                to={`${activity._id}`}
                dashboardType="school"
              />
            ))}
          </ProductsGrid>

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <p>No Activities found for {query}</p>
      )}
    </SearchResultsContainer>
  );
};

export default SearchResults;

// Styled components
const SearchResultsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 0;

  h3 {
    font-size: 1.1rem;
    color: ${colors.dark};
    font-weight: 500;
    width: 80%;
    margin: 1rem auto auto auto;
    text-transform: capitalize;
  }
`;

const ProductsGrid = styled.div`
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${screens.microtab}) {
    grid-template-columns: 1fr;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 40%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;
