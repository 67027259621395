import React, { useState } from "react";
import { styled } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { colors, screens } from "../../utils";
import { selectProvider } from "../../app/features/providerSlice";
import { useGetProviderDataQuery } from "../../app/services/providerApi";
import Spinner from "../../utils/Spinner";
import { IoLogoBuffer } from "react-icons/io";
import { IoSchoolOutline } from "react-icons/io5";
import { MdOutlinePayments } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import ProviderDashboardCard from "./ProviderDashboardCard";
import { Button } from "../FormElements";
import { useNavigate } from "react-router-dom";
import scheduleImage from "../../assets/images/google-calendar-icon.png";

const ProviderScreens = () => {
  const navigate = useNavigate();
  const { firstName, id: providerId } = useAppSelector(selectProvider);

  const { data, isLoading } = useGetProviderDataQuery(providerId);

  const handleManageSchedule = () => {
    navigate("/provider-dashboard/schedules");
  };

  return (
    <Container>
      <Wrapper>
        <TopWrapper>
          <h1>Welcome back, {firstName}</h1>
        </TopWrapper>

        <CardWrapper>
          <ProviderDashboardCard
            linkTo="activities"
            count={
              isLoading ? (
                <Spinner color={colors.grey} />
              ) : data ? (
                `${data.numberOfActivities}`
              ) : (
                0
              )
            }
            text="Activities"
            icon={<IoLogoBuffer size={25} />}
          />
          <ProviderDashboardCard
            linkTo="schools"
            count={
              isLoading ? (
                <Spinner color={colors.grey} />
              ) : data ? (
                `${data.numberOfSchools}`
              ) : (
                0
              )
            }
            text="Schools"
            icon={<IoSchoolOutline size={25} />}
          />
          <ProviderDashboardCard
            linkTo="payments"
            count={
              data ? (
                data.totalPayments
              ) : isLoading ? (
                <Spinner color={colors.grey} />
              ) : (
                0
              )
            }
            text="Payments"
            icon={<MdOutlinePayments size={25} />}
          />

          <ProviderDashboardCard
            linkTo="payments"
            count={
              data ? (
                data.totalPayments * 0.4
              ) : isLoading ? (
                <Spinner color={colors.grey} />
              ) : (
                0
              )
            }
            text="Commission"
            icon={<GiMoneyStack size={25} />}
          />
        </CardWrapper>

        <ContentWrapper>
          <TextContent>
            <h3>Schedule Manager</h3>
            <p>
              Manage all activities and schedules. Create sessions, online
              meetings and so on
            </p>
            <ButtonWrapper>
              <Button
                text="Manage Activity Schedules"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                onClick={handleManageSchedule}
              />
            </ButtonWrapper>
          </TextContent>
          <ImageWrapper>
            <img src={scheduleImage} alt="" />
          </ImageWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ProviderScreens;

const Container = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  & > * {
    flex: 1 1 calc(33.333% - 1.5rem);
    max-width: calc(33.333% - 1.5rem);
  }

  @media (max-width: ${screens.tab}) {
    flex-direction: row;
    & > * {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.5rem;
  gap: 2rem;
  margin: 1rem auto;
  background-color: ${colors.blueLight};
  height: fit-content;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
