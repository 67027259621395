import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useGetActivityByIdQuery,
  useMarkRegisterMutation,
} from "../../../app/services/schoolApi";
import { useParams } from "react-router-dom";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";

type RegisterEntry = {
  attendance: boolean;
  score: string | number;
};

type RegisterData = {
  [key: string]: RegisterEntry;
};

const ActivityRegister = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams();
  const { data: activityData, isLoading, isError } = useGetActivityByIdQuery({
    activityId,
    schoolId,
  });

  const [markRegister] = useMarkRegisterMutation();
  const [registerData, setRegisterData] = useState<RegisterData>({});

  // Initialize register data when activity data is available
  useEffect(() => {
    if (activityData?.register) {
      const initialData = activityData.register.reduce((acc: any, entry: any) => {
        const key = `${entry.student}-${entry.schedule}`;
        acc[key] = {
          attendance: entry.attendance,
          score: entry.score,
        };
        return acc;
      }, {});
      setRegisterData(initialData);
    }
  }, [activityData]);

  // Handle attendance change
  const handleAttendanceChange = (studentId: string, sessionId: string, checked: boolean) => {
    const key = `${studentId}-${sessionId}`;
    console.log("Handling Attendance Change for:", key); // Debugging
    setRegisterData((prevData: any) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        attendance: checked,
      },
    }));
    console.log("Updated Register Data (Attendance):", registerData); // Debugging
  };

  // Handle score change
  const handleScoreChange = (studentId: string, sessionId: string, value: string) => {
    const key = `${studentId}-${sessionId}`;
    console.log("Handling Score Change for:", key); // Debugging
    setRegisterData((prevData: any) => ({
      ...prevData,
      [key]: {
        ...prevData[key],
        score: value,
      },
    }));
    console.log("Updated Register Data (Score):", registerData); // Debugging
  };

  // Add click handler for updating attendance and score
  const handleAddClick = async (studentId: string, sessionId: string) => {
    const key = `${studentId}-${sessionId}`;
    const { attendance, score } = registerData[key] || {};

    try {
      console.log("Submitting for:", key); // Debugging
      await markRegister({
        schoolId,
        activityId,
        scheduleId: sessionId,
        studentId,
        registerData: {
          attendance,
          score,
        },
      });
    } catch (error) {
      console.error("Failed to update register:", error);
    }
  };

  if (isLoading) return <Spinner color={colors.grey} />;
  if (isError) return <p>Something went wrong!</p>;
  if (!activityData) return <p>No activity data found!</p>;

  return (
    <TableContainer>
      <Wrapper>
        <h3>Activity Register</h3>
        <TableWrapper>
          <StyledTable>
            <thead>
              <tr>
                <th>Student Name</th>
                {activityData?.schedules?.map((session: any) => (
                  <th key={session._id}>
                    <div>
                      <p>{new Date(session.date).toLocaleDateString()}</p>
                      <small>{session.title}</small>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {activityData?.students?.map((student: any) => (
                <tr key={student._id}>
                  <td>{student.fullName}</td>
                  {activityData?.schedules?.map((session: any) => {
                    const key = `${student._id}-${session._id}`;
                    const registerEntry = registerData[key] || {};

                    return (
                      <td key={`${student._id}-${session._id}`}>
                        <InputGroup>
                          <label>
                            Att:
                            <input
                              type="checkbox"
                              checked={registerEntry.attendance || false}
                              onChange={(e) =>
                                handleAttendanceChange(
                                  student._id,
                                  session._id,
                                  e.target.checked
                                )
                              }
                            />
                          </label>
                          <label>
                            Score:
                            <input
                              type="number"
                              value={registerEntry.score || ""}
                              onChange={(e) =>
                                handleScoreChange(
                                  student._id,
                                  session._id,
                                  e.target.value
                                )
                              }
                            />
                          </label>
                          <button
                            onClick={() =>
                              handleAddClick(student._id, session._id)
                            }
                          >
                            Add
                          </button>
                        </InputGroup>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </TableWrapper>
      </Wrapper>
    </TableContainer>
  );
};

export default ActivityRegister;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  width: 95%;
  max-width: 1200px;
  flex-direction: column;

  h3 {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.8rem;
  color: ${colors.dark};

  th,
  td {
    padding: 0.5rem;
    border: 1px solid ${colors.lightTer};
    text-align: left;
    white-space: nowrap;
    width: auto;
  }

  th:first-child {
    font-size: 0.8rem;
  }

  th {
    background-color: ${colors.primary};
    color: ${colors.white};
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 600;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;

      p {
        margin: 0;
        font-size: 0.8rem;
      }

      small {
        font-size: 0.7rem;
        color: ${colors.white};
      }
    }
  }

  td:first-child {
    position: sticky;
    left: 0;
    background-color: ${colors.white};
    z-index: 1;
    font-weight: 500;
    color: ${colors.dark};
    font-size: 0.8rem;
  }

  td {
    background-color: ${colors.white};
    border-radius: 0.5rem;
  }

  tbody tr {
    &:hover {
      background-color: ${colors.lightTer};
    }
  }
`;

const InputGroup = styled.div`
  display: flex;
  gap: 0.75rem;
  width: fit-content;

  label {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: ${colors.dark};

    input[type="checkbox"] {
      margin-left: 0.5rem;
      transform: scale(1.2);
      cursor: pointer;
    }

    input[type="number"] {
      margin-left: 0.5rem;
      width: 50px;
      padding: 0.3rem;
      border: 1px solid ${colors.secondary};
      border-radius: 0.25rem;
      font-size: 0.8rem;
      color: ${colors.dark};
    }
  }

  button {
    padding: 0.3rem 0.5rem;
    background-color: ${colors.secondary};
    color: ${colors.white};
    border: none;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    cursor: pointer;

    &:hover {
      background-color: ${colors.primary};
    }
  }
`;
