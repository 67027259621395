import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import Form from "../../FormElements/Form";
import { Button, Input, Label, PasswordInput } from "../../FormElements";
import useForm, { FormValues } from "../../../hooks/useForm";
import { colors, screens } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { useFetchStatesAndLgas } from "../../../hooks/useFetchStatesAndLgas";
import { CustomError } from "../../../types";
import { selectSchool, setSchool } from "../../../app/features/schoolSlice";
import { useEditSchoolMutation } from "../../../app/services/schoolApi";

const SchoolSettings = () => {
  const dispatch = useAppDispatch();
  const school = useAppSelector(selectSchool);
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas(
    school.state
  );
  const [editSchool, { data, isLoading, isSuccess, isError, error }] =
    useEditSchoolMutation();
  const [passwordType, setPasswordType] = useState("password");

  // Initialize form state with existing school data
  const { formValues, handleInputChange } = useForm({
    name: school.name || "",
    email: school.email || "",
    address: school.address || "",
    website: school.website || "",
    phoneNo: school.phoneNo || "",
    country: school.country || "Nigeria",
    state: school.state || "",
    lga: school.lga || "",
    password: "",
    confirmPassword: "",
  });

  const {
    name,
    email,
    address,
    website,
    phoneNo,
    country,
    state,
    lga,
    password,
    confirmPassword,
  } = formValues;

  // Toggle Password visibility
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: { name: "state", value: selectedState },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedData = {
      name,
      email,
      address,
      website,
      phoneNo,
      country,
      state,
      lga,
      password: password || undefined,
    };
    try {
      await editSchool({ schoolId: school.id, updatedData });
    } catch (error) {
      toast.error("Failed to update school details");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSchool({
          id: data.school.id,
          name: data.school.name,
          address: data.school.address,
          email: data.school.email,
          website: data.school.website,
          phoneNo: data.school.phoneNo,
          country: data.school.country,
          state: data.school.state,
          lga: data.school.lga,
          token: data.token,
        })
      );
      toast.success("Profile updated successfully!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <Heading>Edit School Profile</Heading>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="Name of School" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={name}
              name="name"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Email" fontSize="1rem" color={colors.dark} />
            <Input
              type="email"
              value={email}
              name="email"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="School Website" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={website}
              name="website"
              onChange={handleInputChange}
              placeholder="Leave empty if not available"
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Phone No" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={phoneNo}
              name="phoneNo"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="School Address" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={address}
              name="address"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Country" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={country}
              name="country"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="State" fontSize="1rem" color={colors.dark} />
            <Select
              value={state}
              onChange={handleStateChange}
              name="state"
              defaultOption="Select state"
              options={states.map((state) => ({
                optionValue: state,
                optionText: state,
              }))}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label
              text="Local Government Area (LGA)"
              fontSize="1rem"
              color={colors.dark}
            />
            <Select
              value={lga}
              onChange={handleInputChange}
              name="lga"
              defaultOption="Select LGA"
              options={lgas.map((lga) => ({
                optionValue: lga,
                optionText: lga,
              }))}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Password" fontSize="1rem" color={colors.dark} />
            <PasswordInput
              type={passwordType}
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onClick={togglePassword}
              passwordType={passwordType}
            />
            <Label
              text="Confirm Password"
              fontSize="1rem"
              color={colors.dark}
            />
            <PasswordInput
              type={passwordType}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputChange}
              onClick={togglePassword}
              passwordType={passwordType}
            />
          </ItemWrapper>
          <Button
            type="submit"
            text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
            color={colors.white}
            backgroundColor={colors.primary}
            border="none"
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default SchoolSettings;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 2rem auto;
  padding: 1rem 4rem;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 90%;
    padding: 1rem;
  }
`;

const Heading = styled.h3`
  font-size: 1.3rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
