import React, { useState, FormEvent, ChangeEvent } from "react";
import { styled } from "styled-components";
import {
  useCreateOnlineMeetingMutation,
  useGetOneScheduleQuery,
} from "../../../app/services/providerApi";
import { useParams } from "react-router-dom";
import { Form, Label, Input, Button } from "../../FormElements";
import Spinner from "../../../utils/Spinner";
import { colors, screens } from "../../../utils";
import Select from "../../FormElements/Select";

const CreateOnlineMeeting = () => {
  const { id: scheduleId } = useParams();
  const [createMeeting, { isLoading, isSuccess, isError }] =
    useCreateOnlineMeetingMutation();
  const { data: schedule, isLoading: scheduleLoading } =
    useGetOneScheduleQuery(scheduleId);

  // Form state
  const [formState, setFormState] = useState({
    duration: 60, // Default duration in minutes
    timeZone: "UTC+1", // Default timezone
  });

  // Handle input changes
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Prepare data for the meeting creation
    const meetingData = {
      scheduleId,
      duration: formState.duration,
      timeZone: formState.timeZone,
    };

    // Call the API to create the meeting
    await createMeeting(meetingData);
  };

  return (
    <Container>
      <Wrapper>
        <h3>Create Online Meeting</h3>

        {/* Display schedule details if available */}
        {scheduleLoading ? (
          <p>Loading schedule details...</p>
        ) : schedule ? (
          <ScheduleDetails>
            <h4>Session Details</h4>
            <p>
              <strong>Title:</strong> {schedule.title}
            </p>
            <p>
              <strong>Description:</strong> {schedule.description}
            </p>
            <p>
              <strong>Date:</strong> {new Date(schedule.date).toLocaleString()}
            </p>
            <p>
              <strong>Type:</strong> {schedule.type}
            </p>
            <p>
              <strong>Students:</strong> {schedule.students.length}
            </p>
            <h5>Schools: {schedule.schools.length} schools</h5>
            <ul>
              {schedule.schools.map((school: any) => (
                <li key={school._id}>
                  <p>
                    <strong>Name:</strong> {school.name}
                  </p>
                </li>
              ))}
            </ul>
          </ScheduleDetails>
        ) : (
          <p>No schedule found.</p>
        )}

        {/* Form to create a meeting */}
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            {/* Duration Input */}
            <Section>
              <Label text="Duration (minutes):" />
              <small>How long will this online session take?</small>
              <Input
                type="number"
                name="duration"
                value={formState.duration}
                onChange={handleChange}
              />
            </Section>

            {/* Timezone Select */}
            <Section>
              <Label text="Timezone:" />
              <small>Select your timezone for this online session</small>
              <Select
                name="timeZone"
                value={formState.timeZone}
                onChange={handleChange}
                defaultOption="Select timezone"
                options={[
                  { optionValue: "Africa/Lagos", optionText: "Lagos (UTC+1)" },
                  { optionValue: "UTC", optionText: "UTC" },
                  {
                    optionValue: "America/New_York",
                    optionText: "New York (UTC-5)",
                  },
                  {
                    optionValue: "Europe/London",
                    optionText: "London (UTC+0)",
                  },
                  {
                    optionValue: "Asia/Kolkata",
                    optionText: "Kolkata (UTC+5:30)",
                  },
                ]}
              />
            </Section>

            {/* Submit Button */}
            <Button
              type="submit"
              text={
                isLoading ? <Spinner color={colors.white} /> : "Create Meeting"
              }
              color="white"
              backgroundColor={isLoading ? colors.grey : colors.primary}
              disabled={isLoading}
              border="none"
            />

            {/* Success/Error Messages */}
            {isSuccess && (
              <SuccessMessage>Meeting created successfully!</SuccessMessage>
            )}
            {isError && <ErrorMessage>Error creating meeting.</ErrorMessage>}
          </Form>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default CreateOnlineMeeting;

// Styled Components
const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 2rem auto;

  @media (max-width: ${screens.tab}) {
    width: 90%;
    margin: 1rem auto;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;

  small {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
  }
`;

const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
`;

const ScheduleDetails = styled.div`
  background-color: ${colors.blueLight};
  padding: 1.5rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: left;
  gap: 1rem;

  h4 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem;
  }

  p {
    font-size: 1rem;
    text-transform: capitalize;
    margin-bottom: 0.5rem;

    strong {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.3rem;
  }
`;
