import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { selectProvider } from "../../../app/features/providerSlice";
import {
  useEditActivityMutation,
  useGetOneActivityQuery,
} from "../../../app/services/providerApi";
import { uploadImage } from "../../../lib/firebase-client";
import { toast } from "react-hot-toast";
import { CustomError } from "../../../types";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Select from "../../FormElements/Select";
import Spinner from "../../../utils/Spinner";
import ReactQuill from "react-quill";

const modeData = [
  { optionText: "Online", optionValue: "online" },
  { optionText: "Onsite", optionValue: "onsite" },
  { optionText: "Hybrid", optionValue: "hybrid" },
];

const initialState = {
  title: "",
  description: "",
  mode: "",
  price: "",
  avatar: "",
};

const EditActivity = () => {
  const navigate = useNavigate();
  const { id: activityId } = useParams();
  const { id: providerId } = useAppSelector(selectProvider);
  const [formValue, setFormValue] = useState(initialState);
  const [localLoading, setLocalLoading] = useState(false);
  const {
    data: existingData,
    isLoading: existingIsLoading,
    isError: existingIsError,
    error: existingError,
  } = useGetOneActivityQuery({ activityId, providerId });
  const [editActivity, { isLoading, isSuccess, isError, error }] =
    useEditActivityMutation();
  const [logoFile, setLogoFile] = useState<File | null>(null);

  const { title, description, mode, price } = formValue;

  useEffect(() => {
    if (existingData) {
      setFormValue({
        title: existingData.title,
        description: existingData.description,
        mode: existingData.mode,
        price: existingData.price.toString(),
        avatar: existingData.avatar,
      });
    }
  }, [existingData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) setLogoFile(file);
  };

  const handleDescriptionChange = (value: string) => {
    setFormValue({ ...formValue, description: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title && description && mode && price && logoFile) {
      setLocalLoading(true);

      try {
        // Pass `existingData.avatar` as `existingImageUrl` to delete the current image
        const logoUrl = await uploadImage(
          logoFile,
          `activityAvatar/${providerId}`,
          existingData?.avatar
        );
        await editActivity({
          updatedData: {
            title,
            description,
            mode,
            price: parseFloat(price),
            avatar: logoUrl,
          },
          providerId,
          activityId,
        });
      } catch {
        toast.error("An error occurred!");
      } finally {
        setLocalLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Activity updated successfully");
      navigate(-1);
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Edit Activity</h3>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="Title of Activity" />
            <Input
              type="text"
              value={title}
              name="title"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Price" />
            <Input
              type="number"
              value={price}
              name="price"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Description" />
            <StyledReactQuill
              value={description}
              onChange={handleDescriptionChange}
              theme="snow"
            />
          </ItemWrapper>

          <ItemWrapper>
            <SelectWrapper>
              <Label
                text="Select Activity Mode"
                fontSize="1rem"
                color={colors.dark}
              />
              <Select
                value={mode}
                name="mode"
                defaultOption="Select mode"
                onChange={handleInputChange}
                options={modeData}
              />
            </SelectWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Activity Thumbnail" />
            <Input type="file" accept="image/*" onChange={handleLogoChange} />
          </ItemWrapper>

          <ButtonWrapper>
            <Button
              color={colors.white}
              type="submit"
              border="none"
              backgroundColor={colors.primary}
              text={
                localLoading ? (
                  <Spinner color={colors.white} />
                ) : (
                  "Update Activity"
                )
              }
              disabled={localLoading || isLoading}
            />
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default EditActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 0.6rem;
  margin: 2rem auto;

  h3 {
    font-size: 1.1rem;
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    border: 1px solid ${colors.primary};
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .ql-container {
    border: 1px solid ${colors.primary};
    border-radius: 0.25rem;
    height: 15rem;
    max-height: fit-content;
  }

  .ql-editor {
    font-family: inherit;
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
