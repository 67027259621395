import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import { useGetSchoolsEnrolledToActivityQuery } from "../../../app/services/providerApi";
import { useAppSelector } from "../../../app/hooks";
import { selectProvider } from "../../../app/features/providerSlice";
import google from "../../../assets/images/google-calendar-icon.png";
import Spinner from "../../../utils/Spinner";
import GoBack from "../../../utils/GoBack";

const ProviderSchoolsEnrolled = () => {
  const navigate = useNavigate();
  const { id: providerId } = useAppSelector(selectProvider);
  const { id } = useParams();
  const { data, isLoading, isError, isSuccess, error } =
    useGetSchoolsEnrolledToActivityQuery({
      providerId: providerId,
      activityId: id,
    });

  const addSchedule = () => {
    navigate("schedule");
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          {isLoading ? (
            <Spinner color={colors.grey} />
          ) : data?.length === 0 ? (
            <p>No school enrolled yet!</p>
          ) : isError ? (
            <p>Oops! Something went wrong. Try again</p>
          ) : (
            data?.map((school: any) => {
              return (
                <SchoolCard key={school._id}>
                  <h2>{school.name}</h2>
                  <p>
                    <strong>Address:</strong> {school.address}
                  </p>
                  <p>
                    <strong>Email:</strong> {school.email}
                  </p>
                  <p>
                    <strong>Website:</strong> {school.website}
                  </p>
                  <p>
                    <strong>Phone No:</strong> {school.phoneNo}
                  </p>
                  <p>
                    <strong>Country:</strong> {school.country}
                  </p>
                  <p>
                    <strong>State:</strong> {school.state}
                  </p>
                  <p>
                    <strong>LGA:</strong> {school.lga}
                  </p>

                  {school.headOfSchool && (
                    <>
                      <h3>Head of School</h3>
                      <p>
                        <strong>Name:</strong> {school.headOfSchool.name}
                      </p>
                      <img
                        src={school.headOfSchool.signature}
                        alt="Head of School Signature"
                        width="100"
                      />
                    </>
                  )}

                  <h3>Students</h3>
                  <p>{school.students.length}</p>
                </SchoolCard>
              );
            })
          )}
        </ContentWrapper>
        {data?.length >= 1 ? (
          <ScheduleButton onClick={addSchedule}>
            <IconWrapper>
              <img src={google} alt="" />
            </IconWrapper>
            <ButtonText>Schedule a Session</ButtonText>
          </ScheduleButton>
        ) : (
          <GoBack />
        )}
      </Wrapper>
    </Container>
  );
};

export default ProviderSchoolsEnrolled;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
  flex-direction: column;
  gap: 1.5rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: ${screens.tab}) {
    flex-direction: column;
  }
`;

const SchoolCard = styled.div`
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;

  img {
    margin-top: 0.5rem;
  }

  h2,
  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.2rem 0;
  }
`;

const ScheduleButton = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
  border: 1px solid ${colors.grey};
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ButtonText = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.black};
`;
