import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../utils";

interface IPartnerDashboardCard {
  linkTo: string;
  count: any;
  text: string;
  icon: React.ReactNode;
}

const PartnerDashboardCard = ({ linkTo, count, text, icon }: IPartnerDashboardCard) => {
  let formattedCount = count;

  if (count >= 1000000) {
    formattedCount = (count / 1000000).toFixed(1) + "m";
  } else if (count >= 100000) {
    formattedCount = (count / 1000).toFixed(0) + "k";
  } else if (count >= 1000) {
    formattedCount = (count / 1000).toFixed(1) + "k";
  }

  return (
    <Container to={`/partner-dashboard/${linkTo}`}>
      <Wrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper>
          {count ? <h3>{formattedCount}</h3> : null}
          <small>{text}</small>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default PartnerDashboardCard;

const Container = styled(Link)`
  display: flex;
  width: 100%;
  background-color: ${colors.offWhite};
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem;
  gap: 0.8rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  height: fit-content;
  color: ${colors.black};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 1.8rem !important;
    margin: 0;
    color: ${colors.black};
    text-transform: uppercase;
  }

  small {
    font-size: 0.8rem;
    color: ${colors.greyText};
  }
`;
