import React, { useState } from "react";
import { useGetAllAdminActivitiesQuery, useGetApprovedActivitiesQuery } from "../../../../app/services/adminApi";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { colors, screens } from "../../../../utils";
import Spinner from "../../../../utils/Spinner";
import ActivityCard from "../../../ActivityCard";
import Pagination from "../../../common/Pagination";

const AllAdminApprovedAndUnapprovedActivities = () => {
    const { data, isError, error, isLoading, isSuccess } =
    useGetAllAdminActivitiesQuery({});

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Calculate total items and pages
  const totalItems = data && data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get products for the current page
  const currentActivities = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
}

  return (
    <Container>
    <Wrapper>
      {isLoading ? (
        <Spinner color={colors.primary} />
      ) : isError ? (
        <p>Oops!! Something went wrong</p>
      ) : data && data.length > 0 ? (
        <ActivityWrapper>
          {currentActivities.map((activity: any) => (
            <ActivityCard
              key={activity._id}
              title={activity.title}
              mode={activity.mode}
              studentsCount={activity.schools.length}
              src={activity.avatar}
              bgCol={colors.white}
              titleCol={colors.black}
              price={activity.price}
              to={`${activity._id}`}
              dashboardType="admin"
            />
          ))}
        </ActivityWrapper>
      ) : (
        <p>No activities available.</p>
      )}

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      )}
    </Wrapper>
  </Container>
  )
}

export default AllAdminApprovedAndUnapprovedActivities

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 2rem;
  gap: 2rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 40%;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ActivityWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: ${screens.tab}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${screens.microtab}) {
    grid-template-columns: 1fr;
  }
`;

const ActivityLink = styled(Link)`
  // Styled Link component
  text-decoration: none;
  color: ${colors.black};
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  h3 {
    margin: 0;
  }
`;
