import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils";
import { Link } from "react-router-dom";

interface IActivityCard {
  bgCol?: string;
  src?: string;
  title?: string;
  titleCol?: string;
  studentsCount?: number;
  mode?: string;
  price?: number | string;
  to: string;
  dashboardType?: "school" | "provider" | "admin";
}

const ActivityCard = ({
  bgCol,
  src,
  title,
  titleCol,
  studentsCount,
  mode,
  price,
  to,
  dashboardType,
}: IActivityCard) => {
  return (
    <Container
      bgCol={bgCol}
      to={
        dashboardType === "provider"
          ? `/provider-dashboard/activities/${to}`
          : dashboardType === "school"
          ? `/school-dashboard/home/marketplace/activities/${to}`
          : dashboardType === "admin"
          ? `/admin-dashboard/activities/${to}`
          : to
      }
    >
      <Wrapper>
        <ImageWrapper>
          <img src={src} alt="" />
        </ImageWrapper>
        <ContentWrapper>
          <Title titleCol={titleCol}>{title}</Title>
          <ModeWrapper>
            <h5>Mode:</h5>
            <p>{mode}</p>
          </ModeWrapper>
          <StudentCountWrapper>
            <h5>Enrolled:</h5>
            <p>{studentsCount}</p>
          </StudentCountWrapper>
          <Price>
            Price:{" "}
            {price
              ? Number(price).toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "Free"}
          </Price>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ActivityCard;

const Container = styled(Link)<{ bgCol?: string }>`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgCol || colors.white};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 1px solid ${colors.primary};
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem;
  flex-direction: column;
  gap: 1.1rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.8rem;
`;

const Title = styled.h4<{ titleCol?: string }>`
  font-size: 1rem;
  color: ${(props) => props.titleCol || colors.black};
  font-weight: 600;
  line-height: 1.4;
  transform: capitalize;
`;

const ModeWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  text-transform: capitalize;

  h5 {
    font-size: 0.9rem;
    padding: 0.3rem;
    color: ${colors.black};
    background-color: ${colors.grey};
  }

  p {
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${colors.black};
  }
`;

const StudentCountWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  text-transform: capitalize;

  h5 {
    font-size: 0.9rem;
    padding: 0.3rem;
    color: ${colors.black};
    background-color: ${colors.grey};
  }

  p {
    font-size: 0.9rem;
    padding: 0.4rem;
    color: ${colors.black};
  }
`;
const Price = styled.h4`
  font-size: 1rem;
  color: ${colors.black};
`;
