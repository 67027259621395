import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetResultQuery } from "../../../app/services/schoolApi";
import Spinner from "../../../utils/Spinner";
import { colors } from "../../../utils";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import OneResult from "./OneResult";
import Pagination from "../../common/Pagination";

interface Parent {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
}

interface Student {
  id: string;
  fullName: string;
  gender: string;
  studentClass: string;
  parents: Parent[];
}

interface RegisterEntry {
  // New interface for the register entries
  score: number;
  attendance: boolean;
}

interface StudentWithScores {
  student: Student;
  totalScore: number;
  maxScore: number;
  percentageScore: number;
  register: RegisterEntry[];
}

interface ActivityWithScores {
  activity: {
    id: string;
    title: string;
    description: string;
  };
  students: StudentWithScores[];
}

const ITEMS_PER_PAGE = 10;

const Results: React.FC = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [studentsToDisplay, setStudentsToDisplay] = useState<
    StudentWithScores[]
  >([]);
  const { data, error, isLoading } = useGetResultQuery({
    schoolId,
    activityId,
  });

  useEffect(() => {
    if (data && data.students) {
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setStudentsToDisplay(data.students.slice(startIndex, endIndex));
    }
  }, [data, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <TopWrapper>
            <h3>Activity Results</h3>
            <h4>{data?.activity?.title}</h4>
            <Description
              dangerouslySetInnerHTML={{ __html: data?.activity?.description }}
            />
          </TopWrapper>

          {isLoading ? (
            <Spinner color={colors.grey} />
          ) : error ? (
            <p>Something went wrong!</p>
          ) : (
            <>
              <Content>
                {studentsToDisplay?.map((studentWithScores) => (
                  <OneResult
                    linkTo={`${studentWithScores?.student?.id}`}
                    key={studentWithScores?.student?.id}
                    name={studentWithScores?.student?.fullName}
                    sessions={studentWithScores?.register?.length}
                    score={`${studentWithScores?.percentageScore?.toFixed(2)}%`}
                  />
                ))}
              </Content>
              <Pagination
                currentPage={page}
                totalItems={data?.students?.length}
                itemsPerPage={ITEMS_PER_PAGE}
                onPageChange={handlePageChange}
              />
              <small>Select a student to view and print their results</small>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Results;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.7rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const RentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 0.9rem;
  }
`;

const Rent = styled.div`
  display: flex;
  width: fit-content;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  em {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  ul,
  ol {
    padding-left: 0.8rem;

    li {
      font-size: 0.9rem;
      line-height: 1.5;
    }
  }
`;
