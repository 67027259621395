import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectAdmin, setAdmin } from "../../../../app/features/adminSlice";
import { useFetchStatesAndLgas } from "../../../../hooks/useFetchStatesAndLgas";
import { useEditAdminMutation } from "../../../../app/services/adminApi";
import useForm from "../../../../hooks/useForm";
import { toast } from "react-hot-toast";
import { CustomError } from "../../../../types";
import {
  Button,
  Form,
  Input,
  Label,
  PasswordInput,
} from "../../../FormElements";
import { colors, screens } from "../../../../utils";
import Select from "../../../FormElements/Select";
import Spinner from "../../../../utils/Spinner";
import { styled } from "styled-components";

const AdminSettings = () => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector(selectAdmin);
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas(admin.state);
  const [editAdmin, { data, isLoading, isSuccess, isError, error }] =
    useEditAdminMutation();
  const [passwordType, setPasswordType] = useState("password");

  // Initialize form state with existing admin data
  const { formValues, handleInputChange } = useForm({
    firstName: admin.firstName || "",
    lastName: admin.lastName || "",
    email: admin.email || "",
    phoneNo: admin.phoneNo || "",
    tag: admin.tag || "",
    country: admin.country || "Nigeria",
    state: admin.state || "",
    lga: admin.lga || "",
    password: "",
    confirmPassword: "",
  });

  const {
    firstName,
    lastName,
    email,
    phoneNo,
    tag,
    country,
    state,
    lga,
    password,
    confirmPassword,
  } = formValues;

  // Toggle Password visibility
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: { name: "state", value: selectedState },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedData = {
      firstName,
      lastName,
      email,
      phoneNo,
      tag,
      country,
      state,
      lga,
      password: password || undefined,
    };
    try {
      await editAdmin({ adminId: admin.id, updatedData });
    } catch (error) {
      toast.error("Failed to update admin details");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setAdmin({
          id: data.admin.id,
          firstName: data.admin.firstName,
          lastName: data.admin.lastName,
          email: data.admin.email,
          phoneNo: data.admin.phoneNo,
          tag: data.admin.tag,
          country: data.admin.country,
          state: data.admin.state,
          lga: data.admin.lga,
          token: data.token,
        })
      );
      toast.success("Profile updated successfully!");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <Heading>Edit your Profile</Heading>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="First Name" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={firstName}
              name="firstName"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Last Name" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={lastName}
              name="lastName"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Email" fontSize="1rem" color={colors.dark} />
            <Input
              type="email"
              value={email}
              name="email"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Phone No" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={phoneNo}
              name="phoneNo"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Country" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={country}
              name="country"
              onChange={handleInputChange}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="State" fontSize="1rem" color={colors.dark} />
            <Select
              value={state}
              onChange={handleStateChange}
              name="state"
              defaultOption="Select state"
              options={states.map((state) => ({
                optionValue: state,
                optionText: state,
              }))}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label
              text="Local Government Area (LGA)"
              fontSize="1rem"
              color={colors.dark}
            />
            <Select
              value={lga}
              onChange={handleInputChange}
              name="lga"
              defaultOption="Select LGA"
              options={lgas.map((lga) => ({
                optionValue: lga,
                optionText: lga,
              }))}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Password" fontSize="1rem" color={colors.dark} />
            <PasswordInput
              type={passwordType}
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onClick={togglePassword}
              passwordType={passwordType}
            />
            <Label
              text="Confirm Password"
              fontSize="1rem"
              color={colors.dark}
            />
            <PasswordInput
              type={passwordType}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputChange}
              onClick={togglePassword}
              passwordType={passwordType}
            />
          </ItemWrapper>
          <Button
            type="submit"
            text={isLoading ? <Spinner color={colors.white} /> : "Save Changes"}
            color={colors.white}
            backgroundColor={colors.primary}
            border="none"
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AdminSettings;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 2rem auto;
  padding: 1rem 4rem;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 90%;
    padding: 1rem;
  }
`;

const Heading = styled.h3`
  font-size: 1.3rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
