import React, { ReactNode } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Navigation from "../../components/common/Navigation";
import { logoutAdmin, selectAdmin } from "../../app/features/adminSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const data = [
  {
    id: 1,
    title: "Dashboard",
    linkTo: "admin-dashboard/home",
  },

  {
    id: 2,
    title: "Providers",
    linkTo: "admin-dashboard/providers",
  },

  {
    id: 3,
    title: "Schools",
    linkTo: "admin-dashboard/schools",
  },

  {
    id: 4,
    title: "Partners",
    linkTo: "admin-dashboard/partners",
  },

  {
    id: 5,
    title: "Activities",
    linkTo: "admin-dashboard/activities",
  },

  {
    id: 6,
    title: "Learning",
    linkTo: "admin-dashboard/learning",
  },

  {
    id: 7,
    title: "Payments",
    linkTo: "admin-dashboard/payments",
  },
  {
    id: 8,
    title: "Settings",
    linkTo: "admin-dashboard/settings",
  },
];

interface IAdminLayout {
  children: ReactNode;
}

const AdminLayout = ({ children }: IAdminLayout) => {
  const { token: adminToken, firstName } = useAppSelector(selectAdmin);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logoutAdmin());
    navigate("/sign-in/admin-signin");
    toast.success("You successfully logged out");
  };



  return (
    <ProtectedRoute token={adminToken} linkTo="/sign-in/admin-signin">
      <Navigation
        token={adminToken}
        firstName={firstName}
        clickLink="admin-signup"
        buttonText="Sign in"
        navData={data}
        handleLogout={handleLogout}
      />
      {children}
    </ProtectedRoute>
  );
};

export default AdminLayout;
