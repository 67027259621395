import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Label } from "../FormElements";
import { colors, screens } from "../../utils";
import Spinner from "../../utils/Spinner";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../app/hooks";
import { uploadImage } from "../../lib/firebase-client";
import { useCreateActivityMutation } from "../../app/services/providerApi";
import { selectProvider } from "../../app/features/providerSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../types";
import Select from "../FormElements/Select";

const modeData = [
  {
    optionText: "Online",
    optionValue: "online",
  },

  {
    optionText: "Onsite",
    optionValue: "onsite",
  },
  {
    optionText: "Hybrid",
    optionValue: "hybrid",
  },
];

const initialState = {
  title: "",
  description: "",
  mode: "",
  price: "",
  avatar: "",
};

const AddActivity = () => {
  const navigate = useNavigate();
  const { id } = useAppSelector(selectProvider);
  const [formValue, setFormValue] = useState(initialState);
  const [addActivity, { isLoading, isSuccess, isError, error }] =
    useCreateActivityMutation();
  const [logoFile, setLogoFile] = useState<File | null>(null);

  const { title, description, mode, price } = formValue;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLogoFile(file);
    }
  };

  // Handle description change
  const handleDescriptionChange = (value: string) => {
    setFormValue({ ...formValue, description: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (title && description && mode && price && logoFile) {
      try {
        const logoUrl = await uploadImage(logoFile, `activityAvatar/${id}`);
        await addActivity({
          activityData: {
            title,
            description,
            mode,
            price,
            avatar: logoUrl,
          },
          providerId: id,
        });
      } catch (error) {
        toast.error("An error occurred!");
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Activity created successfully");
      navigate("/provider-dashboard/activities");
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Add a new Activity</h3>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="Title of Activity" />
            <Input
              type="text"
              value={title}
              name="title"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Price" />
            <Input
              type="number"
              value={price}
              name="price"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Description" />
            <StyledReactQuill
              value={description}
              onChange={handleDescriptionChange}
              theme="snow"
            />
          </ItemWrapper>

          <ItemWrapper>
            <SelectWrapper>
              <Label
                text="Select Activity Mode"
                fontSize="1rem"
                color={colors.dark}
              />
              <Select
                value={mode}
                name="mode"
                defaultOption="Select mode"
                onChange={handleInputChange}
                options={modeData}
              />
            </SelectWrapper>
          </ItemWrapper>
          <ItemWrapper>
            <Label text="Activity Thumbnail" />
            <Input type="file" accept="image/*" onChange={handleLogoChange} />
          </ItemWrapper>
          <ButtonWrapper>
            <Button
              color={colors.white}
              type="submit"
              border="none"
              backgroundColor={colors.primary}
              text={
                isLoading ? <Spinner color={colors.white} /> : "Add Activity"
              }
              disabled={isLoading}
            />
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 0.6rem;
  margin: 2rem auto;

  h3 {
    font-size: 1.1rem;
  }

  @media (max-width: ${screens.tab}) {
    width: 90%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    border: 1px solid ${colors.primary};
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .ql-container {
    border: 1px solid ${colors.primary};
    border-radius: 0.25rem;
    height: 15rem;
    max-height: fit-content;
  }

  .ql-editor {
    font-family: inherit;
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
