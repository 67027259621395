import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils";
import { Button } from "../FormElements";
import { useNavigate } from "react-router-dom";

const Enrolled = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-2);
  };
  return (
    <Container>
      <Heading>Enrollment Successful!</Heading>
      <Message>
        You have successfully enrolled in the activity. Check your dashboard for
        more details.
      </Message>
      <ButtonWrapper>
        <Button
          text="Go back to Marketplace"
          border="none"
          backgroundColor={colors.primary}
          color={colors.white}
          onClick={handleClick}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default Enrolled;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
  gap: 1rem;
`;

const Heading = styled.h2`
  color: ${colors.primary};
  font-size: 1.1rem;
  font-weight: 500;
`;

const Message = styled.p`
  color: ${colors.black};
  font-size: 0.9rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
