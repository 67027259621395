import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  tag: "",
  token: null,
  id: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    createAdmin: (state, action) => {},

    setAdmin: (state, action) => {
      localStorage.setItem(
        "admin",
        JSON.stringify({
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          phoneNo: action.payload.phoneNo,
          tag: action.payload.tag,
          token: action.payload.token,
        })
      );
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.tag = action.payload.tag;
      state.token = action.payload.token;
    },

    logoutAdmin: (state) => {
      localStorage.clear();
      state.firstName = "";
      state.lastName = "";
      state.id = null;
      state.email = "";
      state.phoneNo = "";
      state.tag = "";
      state.token = null;
    },
  },
});

export const selectAdmin = (state: any) => state.admin;

export const { createAdmin, setAdmin, logoutAdmin } = adminSlice.actions;

export default adminSlice.reducer;

