import React, { useState, useEffect } from "react";
import {
  useGetAllParentsQuery,
  useGetAllStudentsQuery,
} from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import Modal from "../../Modal";
import AddStudent from "./AddStudent";
import { Button } from "../../FormElements";
import { colors, screens } from "../../../utils";
import Student from "./Student";
import { styled } from "styled-components";
import Spinner from "../../../utils/Spinner";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

const SchoolStudents = () => {
  const navigate = useNavigate()
  const { id } = useAppSelector(selectSchool);
  const { data, isLoading, isError } = useGetAllStudentsQuery(id);
  const { data: parentData } = useGetAllParentsQuery({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [studentsToDisplay, setStudentsToDisplay] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setStudentsToDisplay(data.slice(startIndex, endIndex));
    }
  }, [data, page]);

  const handleAddStudent = () => {
    setIsOpen(true);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleAddMany = () => {
    navigate("add-many")
  }

  return (
    <Container>
      <Wrapper>
        {isLoading ? (
          <Spinner color={colors.grey} />
        ) : data?.length === 0 ? (
          <ContentWrapper>
            <p>You have not added any students</p>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
              <AddStudent closeModal={() => setIsOpen(false)} />
            </Modal>
            <Button
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
              onClick={handleAddStudent}
              text="Add a student"
            />
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <TopWrapper>
              <h3>List of students</h3>
              <ButtonContainer>
                <ButtonWrapper>
                  <Button
                    color={colors.white}
                    backgroundColor={colors.primary}
                    border="none"
                    onClick={handleAddStudent}
                    text="Add a Student"
                  />
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    color={colors.white}
                    backgroundColor={colors.primary}
                    border="none"
                    onClick={handleAddMany}
                    text="Add Bulk"
                  />
                </ButtonWrapper>
              </ButtonContainer>
            </TopWrapper>

            <Content>
              {studentsToDisplay.map((student: any) => (
                <Student
                  key={student._id}
                  linkTo={student._id}
                  fullName={student.fullName}
                  gender={student.gender}
                  studentClass={student.studentClass}
                  studentId={student._id}
                />
              ))}
            </Content>
            <Pagination
              currentPage={page}
              totalItems={data && data.length}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={handlePageChange}
            />
            <small>
              Select a student to manage their activities and parents
            </small>
          </ContentWrapper>
        )}

        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <AddStudent closeModal={() => setIsOpen(false)} />
        </Modal>
      </Wrapper>
    </Container>
  );
};

export default SchoolStudents;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  margin: 2rem auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 1.5rem;

  small {
    font-size: 0.7rem;
    color: ${colors.primary};
    font-weight: 700;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: fit-content;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
