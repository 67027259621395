import React, { FormEvent, useEffect, useState } from "react";
import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Label } from "../../FormElements";
import { colors } from "../../../utils";
import Spinner from "../../../utils/Spinner";
import Select from "../../FormElements/Select";
import { CustomError } from "../../../types";
import { useAddStudentMutation } from "../../../app/services/schoolApi";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { uploadImage } from "../../../lib/firebase-client";

interface IAddStudent {
  closeModal: () => void;
}

const genderData = [
  {
    optionText: "Male",
    optionValue: "male",
  },
  {
    optionText: "Female",
    optionValue: "female",
  },
];

const classData = [
  {
    optionText: "Primary 1",
    optionValue: "primary1",
  },
  {
    optionText: "Primary 2",
    optionValue: "primary2",
  },
  {
    optionText: "Primary 3",
    optionValue: "primary3",
  },
  {
    optionText: "Primary 4",
    optionValue: "primary4",
  },
  {
    optionText: "Primary 5",
    optionValue: "primary5",
  },
  {
    optionText: "Primary 6",
    optionValue: "primary6",
  },
  {
    optionText: "JSS 1",
    optionValue: "jss1",
  },
  {
    optionText: "JSS 2",
    optionValue: "jss2",
  },
  {
    optionText: "JSS 3",
    optionValue: "jss3",
  },
  {
    optionText: "SSS 1",
    optionValue: "sss1",
  },
  {
    optionText: "SSS 2",
    optionValue: "sss2",
  },
  {
    optionText: "SSS 3",
    optionValue: "sss3",
  },
];

const initialState = {
  fullName: "",
  gender: "",
  studentClass: "",
  image: null as File | null,
};

const AddStudent = ({ closeModal }: IAddStudent) => {
  const { id } = useAppSelector(selectSchool);
  const [formValue, setFormValue] = useState(initialState);
  const [AddStudent, { isLoading, isSuccess, isError, error }] =
    useAddStudentMutation();

  const { fullName, gender, studentClass, image } = formValue;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.files) {
      setFormValue({ ...formValue, [name]: e.target.files[0] });
    } else {
      setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fullName && gender && studentClass) {
      let imageUrl = "";

      if (image) {
        try {
          // Upload image and get the URL
          imageUrl = await uploadImage(image, `students/${id}`);
        } catch (uploadError) {
          toast.error("Failed to upload image. Please try again.");
          return;
        }
      }

      await AddStudent({
        schoolId: id,
        studentData: { fullName, gender, studentClass, avatar: imageUrl },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Student added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
      return;
    }
  }, [isSuccess, isError, error]);

  return (
    <Container>
      <Wrapper>
        <h3>Onboard a student</h3>
        <Form onSubmit={handleSubmit}>
          <Label text="Student's full name" />
          <Input
            type="text"
            value={fullName}
            name="fullName"
            onChange={handleInputChange}
          />

          <ItemWrapper>
            <Label
              text="Student's gender"
              fontSize="1rem"
              color={colors.dark}
            />
            <Select
              value={gender}
              name="gender"
              defaultOption="Select gender"
              onChange={handleInputChange}
              options={genderData}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Student's class" fontSize="1rem" color={colors.dark} />
            <Select
              value={studentClass}
              name="studentClass"
              defaultOption="Select class"
              onChange={handleInputChange}
              options={classData}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Student's image" fontSize="1rem" color={colors.dark} />
            <Input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <Button
            color={colors.white}
            type="submit"
            border="none"
            backgroundColor={colors.primary}
            text={isLoading ? <Spinner color={colors.white} /> : "Add Student"}
            disabled={isLoading}
          />
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddStudent;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
