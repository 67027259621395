import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Label } from "../FormElements";
import { colors, screens } from "../../utils";
import Spinner from "../../utils/Spinner";
import { useAddSchoolMutation } from "../../app/services/partnerApi";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../app/hooks";
import { selectPartner } from "../../app/features/partnerSlice";
import { useFetchStatesAndLgas } from "../../hooks/useFetchStatesAndLgas";
import Select from "../FormElements/Select";
import { CustomError } from "../../types";
import { uploadImage } from "../../lib/firebase-client";

interface IAddProvider {
  closeModal: () => void;
}

const initialState = {
  name: "",
  address: "",
  email: "",
  website: "",
  phoneNo: "",
  country: "Nigeria",
  state: "",
  lga: "",
  logo: "",
};

const AddSchool = ({ closeModal }: IAddProvider) => {
  const { id } = useAppSelector(selectPartner);
  const [formValue, setFormValue] = useState(initialState);
  const { states, lgas, setSelectedState } = useFetchStatesAndLgas("");
  const [addSchool, { isLoading, isSuccess, isError, error }] =
    useAddSchoolMutation();
  const [logoFile, setLogoFile] = useState<File | null>(null); // State to handle the logo file
  const [isUploading, setIsUploading] = useState(false); // New state to handle upload spinner

  const { name, address, email, website, phoneNo, country, state, lga } =
    formValue;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLogoFile(file);
    }
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value;
    handleInputChange({
      target: {
        name: "state",
        value: selectedState,
      },
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedState(selectedState);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      name &&
      address &&
      email &&
      website &&
      phoneNo &&
      country &&
      state &&
      lga &&
      logoFile
    ) {
      setIsUploading(true); // Trigger spinner immediately when form is submitted

      try {
        const logoUrl = await uploadImage(logoFile, `schoolLogos/${id}`);
        await addSchool({
          schoolData: {
            name,
            address,
            email,
            website,
            phoneNo,
            country,
            state,
            lga,
            logo: logoUrl,
          },
          partnerId: id,
        });
      } catch (error) {
        toast.error("Failed to upload logo");
      } finally {
        setIsUploading(false); // Stop the spinner after the upload process is done
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("School added successfully");
      closeModal();
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error, closeModal]);

  return (
    <Container>
      <Wrapper>
        <h3>Add a new School</h3>
        <Form onSubmit={handleSubmit}>
          <ItemWrapper>
            <Label text="School Name" />
            <Input
              type="text"
              value={name}
              name="name"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="School Address" />
            <Input
              type="text"
              value={address}
              name="address"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Official school email" />
            <Input
              type="email"
              value={email}
              name="email"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="School phone no" />
            <Input
              type="text"
              value={phoneNo}
              name="phoneNo"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="School website" />
            <Input
              type="text"
              value={website}
              name="website"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Label text="Country" fontSize="1rem" color={colors.dark} />
            <Input
              type="text"
              value={country}
              name="country"
              onChange={handleInputChange}
            />
          </ItemWrapper>

          <ItemWrapper>
            <SelectWrapper>
              <Label text="State" fontSize="1rem" color={colors.dark} />
              <Select
                value={state}
                onChange={handleStateChange}
                name="state"
                defaultOption="Select state"
                options={states.map((state) => ({
                  optionValue: state,
                  optionText: state,
                }))}
              />
            </SelectWrapper>

            <SelectWrapper>
              <Label
                text="Local Government Area (LGA)"
                fontSize="1rem"
                color={colors.dark}
              />
              <Select
                value={lga}
                onChange={(e: any) => handleInputChange(e)}
                name="lga"
                defaultOption="Select LGA"
                options={lgas.map((lga) => ({
                  optionValue: lga,
                  optionText: lga,
                }))}
              />
            </SelectWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <Label text="School Logo" />
            <Input type="file" accept="image/*" onChange={handleLogoChange} />
          </ItemWrapper>

          <ButtonWrapper>
            <Button
              color={colors.white}
              type="submit"
              border="none"
              backgroundColor={colors.primary}
              text={
                isLoading || isUploading ? (
                  <Spinner color={colors.white} />
                ) : (
                  "Add School"
                )
              }
              disabled={isLoading || isUploading} // Disable the button while loading
            />
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default AddSchool;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 0.6rem;
  margin: auto;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
